import React, { useState, useEffect } from 'react'
import { FileOutlined } from '@ant-design/icons'
import { Input, Modal, Button, Popconfirm, Tag, Row, Col, Spin, message, Carousel, Image } from 'antd'
import styled from 'styled-components'
import { Formik } from 'formik'
import moment from 'moment'
import Table from '../Table'
import TextBase from '../Text'
import ContentTab from './ContentTab'
import ReportProblemForm from '../ReportProblemForm'
import httpServiceHealthCare from '../../services/httpServiceHealthCare'
const { Search: SearchAnt } = Input
const Text = styled(TextBase)`
  font-weight: bold;
`
const Search = styled(SearchAnt)`
  i {
    color: ${(props) => props.theme.primaryButton};
  }
`

const ImageKYC = styled(Image)`
  cursor: pointer;
  width: 50%;
  height: 50%;
  object-fiw: contain;
`

const CarouselBlack = styled(Carousel)`
  .slick-dots li {
    background: black;
  }
`

const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`
const Container = styled.div`
  width: 100%;
  padding: 20px;
`
const KycProblemButton = styled(Button)`
  background: #f5b400;
  border: 1px solid #f9c710;
  color: white;
  &:hover {
    background: #ffebb5;
    border: 1px solid #f9c710;
    color: #f5b400;
  }
`

function UserListTable({
  state,
  verifyStatus,
  fetchUserImage,
  checkUserDetail,
  resetPin,
  refreshData,
  deleteAccount,
  cancelDeleteAccount,
  loading,
  imageLoading,
  fetchUserFcmToken,
  triggerUpdateDataWithPatientInfo,
  setCurrentTab,
  currentTab,
  history,
  ...rest
}) {
  const [profileImage, setProfileImage] = useState([])
  const [visiblePatientProfile, setVisiblePatientProfile] = useState(false)
  const [patientId, setPatientId] = useState()
  const [userId, setUserId] = useState()
  const [allUserListData, setAllUserListData] = useState(false)
  const [kycWaitingListData, setKycWaitingListData] = useState(false)
  const [problemsListData, setProblemsListData] = useState(false)
  const [requestToDeleteListData, setRequestToDeleteListData] = useState(false)
  const [filterAllUserListData, setFilterAllUserListData] = useState(false)
  const [filterKycWaitingListData, setFilterKycWaitingListData] = useState(false)
  const [filterProblemsListData, setFilterProblemsListData] = useState(false)
  const [filterRequestToDeleteListData, setFilterRequestToDeleteListData] = useState(false)
  const [noteProblems, setNoteProblems] = useState(false)
  const [searchText, setSearchText] = useState(false)
  const [showColumnByTab, setShowColumnByTab] = useState(false)
  const [visibleReportProblemModal, setVisibleReportProblemModal] = useState(false)
  const [visibleKycProblemInfoModal, setVisibleKycProblemInfoModal] = useState(false)
  const [modalPin, setModalPin] = useState(false)
  const [pinCode, setPinCode] = useState('')
  const setColor = (status) => {
    if (status) {
      return 'green'
    }
    return 'red'
  }

  useEffect(() => {
    setProfileImage(state.images)
  }, [state.images])

  useEffect(() => {
    if (history?.location?.state?.key === 'kycWaitingList') {
      setCurrentTab('kycWaitingList')
    }
  }, [])

  useEffect(() => {
    clearTableData()
    const data = filterDataByCategory(state.dataSource)
    if (searchText) {
      onSearch(searchText, data)
    }
  }, [state.dataSource])

  const filterDataByCategory = (data) => {
    const allList = data.filter((item) => !item.kycProblem || !item.kycProblem.status)
    const kycWaiting = data.filter((item) => !item.verifyId && (!item.kycProblem || !item.kycProblem.status))
    const problems = data.filter((item) => item.kycProblem && item.kycProblem.status)
    const requestToDelete = data.filter((item) => item.toBeDeleted && item.toBeDeleted === true)
    const dataFiltered = {
      allUserListData: allList,
      kycWaitingListData: kycWaiting,
      problemsListData: problems,
      requestToDeleteListData: requestToDelete,
    }
    setAllUserListData(allList)
    setKycWaitingListData(kycWaiting)
    setProblemsListData(problems)
    setRequestToDeleteListData(requestToDelete)
    return dataFiltered
  }

  const clearTableData = () => {
    setAllUserListData([])
    setKycWaitingListData([])
    setProblemsListData([])
    setRequestToDeleteListData([])
  }

  const showModalPin = (pin) => {
    setPinCode(pin)
  }
  const changeKycProblemStatus = async (value, isFixProblem = false) => {
    let data = {}

    if (isFixProblem) {
      data = { kycProblem: null }
    } else {
      data = {
        kycProblem: {
          status: true,
          problems: value.problems,
        },
      }
    }
    const res = await httpServiceHealthCare.patch(`/UserInfos/${patientId}`, data)
    if (res && res.status === 200) {
      message.success('เปลี่ยนสถานะเรียบร้อยแล้ว')
      if (!isFixProblem) fetchUserFcmToken(userId, `การยืนยันตัวตนของคุณถูกปฎิเสธ เนื่องจาก${value.problems} รบกวนสมัครใหม่อีกครั้งแล้วตรวจสอบรายละเอียดให้ถูกต้อง`)
    } else {
      message.error('เกิดข้อผิดพลาด')
    }
    isFixProblem === false && setVisibleReportProblemModal(false)
    refreshData()
  }

  const columns = [
    {
      title: 'ไอดีผู้ใช้(OMA)',
      key: 'customerID',
      width: 150,
      render: (record) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setVisiblePatientProfile(true)
            fetchUserImage(record.userId)
          }}
        >
          <Text>{record.userId}</Text>
        </div>
      ),
      align: 'center',
    },
    {
      title: 'ชื่อ',
      key: 'date',
      width: 150,
      render: (record) => (
        <Text
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setVisiblePatientProfile(true)
            fetchUserImage(record.userId)
          }}
        >
          {record.firstname} {record.lastname}
        </Text>
      ),
    },
    {
      title: 'ชื่อผู้ใช้งาน',
      key: 'email',
      width: 150,
      render: (record) => <Text onClick={() => console.log('log ::', record)}>{record.email}</Text>,
    },
    {
      title: 'เลขบัตรประชาชน',
      key: 'cId',
      width: 150,
      render: (record) => <Text>{record.cId}</Text>,
    },
    {
      title: 'เบอรโทรศัพท์',
      key: 'mobileNumber',
      width: 150,
      render: (record) => <Text>{record.mobileNumber}</Text>,
    },
    {
      title: 'เวลาที่ลงทะเบียน',
      key: 'createdAt',
      width: 150,
      render: (record) => <Text>{record.created_at ? moment(record.created_at).format('DD/MM/YYYY - HH:mm') : '-'}</Text>,
    },
    currentTab === 'requestToDeleteList'
      ? {
          title: 'เหตุผลลบบัญชี',
          key: 'createdAt',
          width: 150,
          render: (record) => <Text>{record?.deletedReason ? record?.deletedReason : '-'}</Text>,
        }
      : {},
    {
      title: 'คำสั่ง',
      key: 'action',
      width: 350,
      render: (record) => {
        if (currentTab === 'requestToDeleteList') {
          return (
            <Row gutter={[8, 4]}>
              <Col span={10}>
                <Popconfirm
                  title="ต้องการจะลบชื่อผู้ใช้ใช่หรือไม่"
                  onConfirm={() => {
                    deleteAccount(record.userId, record.id)
                  }}
                  okText="ใช่"
                  cancelText="ไม่"
                >
                  <Button type="danger">ลบบัญชีผู้ใช้งาน</Button>
                </Popconfirm>
              </Col>
              <Col span={10}>
                <Popconfirm
                  title="ต้องการยกเลิกคำขอลบผู้ใช้ใช่หรือไม่"
                  onConfirm={() => {
                    cancelDeleteAccount(record.id)
                  }}
                  okText="ใช่"
                  cancelText="ไม่"
                >
                  <Button type="primary">ยกเลิกคำขอลบบัญชี</Button>
                </Popconfirm>
              </Col>
            </Row>
          )
        } else {
          return !record.verifyId && (!record.kycProblem || !record.kycProblem.status) ? (
            <Row gutter={[8, 4]}>
              <Col span={7}>
                <Button
                  type="primary"
                  onClick={() => {
                    setPatientId(record.id)
                    setUserId(record.userId)
                    checkUserDetail(record.userId)
                    fetchUserImage(record.userId)
                    setVisiblePatientProfile(true)
                  }}
                >
                  ยืนยันตัวตน
                </Button>
              </Col>
              <Col span={7}>
                <Popconfirm
                  title="ต้องการจะลบชื่อผู้ใช้ใช่หรือไม่"
                  onConfirm={() => {
                    deleteAccount(record.userId, record.id)
                  }}
                  okText="ใช่"
                  cancelText="ไม่"
                >
                  <Button type="danger">ลบผู้ใช้งาน</Button>
                </Popconfirm>
              </Col>
              <Col span={7}>
                <KycProblemButton
                  onClick={() => {
                    setPatientId(record.id)
                    setUserId(record.userId)
                    setVisibleReportProblemModal(true)
                  }}
                >
                  KYC มีปัญหา
                </KycProblemButton>
              </Col>
            </Row>
          ) : record.verifyId && (!record.kycProblem || !record.kycProblem.status) ? (
            <Row>
              {/* {record.resetPasswordStatus === 'wating_reset_password'
                ?
                <Col span={11}>
                  <Popconfirm
                    title="ต้องการรีเซ็ตรหัสผ่านใช่หรือไม่"
                    onConfirm={async () => {
                      resetPin(record.userId)
                      setModalPin(true)
                    }}
                    okText="ใช่"
                    cancelText="ไม่"
                  >
                    <Button danger>{`รีเซ็ตรหัสผ่าน (APP)`}</Button>
                  </Popconfirm>
                </Col>
                :
                <Col span={9}>
                  <Popconfirm
                    title="ต้องการรีเซ็ตรหัสผ่านใช่หรือไม่"
                    onConfirm={() => {
                      resetPin(record.userId)
                    }}
                    okText="ใช่"
                    cancelText="ไม่"
                  >
                    <Button type="primary">รีเซ็ตรหัสผ่าน</Button>
                  </Popconfirm>
                </Col>
              } */}
              <Col>
                <Popconfirm
                  title="ต้องการยกเลิกการยืนยันตัวตนใช่หรือไม่"
                  onConfirm={() => {
                    verifyStatus(record.id, false)
                    fetchUserFcmToken(record.userId, 'การยืนยันตัวตนของคุณถูกปฎิเสธ   รบกวนสมัครใหม่อีกครั้งแล้วตรวจสอบรายละเอียดให้ถูกต้อง')
                  }}
                  okText="ใช่"
                  cancelText="ไม่"
                >
                  <Button type="danger">ยกเลิกการยืนยันตัวตน</Button>
                </Popconfirm>
              </Col>
            </Row>
          ) : !record.verifyId && (record.kycProblem || record.kycProblem.status) ? (
            <Popconfirm
              title="แก้ไขปัญเรียบร้อยแล้วใช่หรือไม่"
              onConfirm={() => {
                changeKycProblemStatus(null, true)
              }}
              okText="ใช่"
              cancelText="ไม่"
            >
              <Button type="primary" onClick={() => setPatientId(record.id)}>
                แก้ไขแล้ว
              </Button>
            </Popconfirm>
          ) : (
            ''
          )
        }
      },
    },
    {
      render: (record) => (record.pin ? showModalPin(record.pin) : ''),
    },
    {
      title: 'สถานะ',
      key: 'verified',
      width: 170,
      align: 'center',
      sorter: (a, b) => {
        let statusA = a.verifyId ? true : false
        let statusB = b.verifyId ? true : false
        if ((statusA && statusB) || (!statusA && !statusB)) {
          return 0
        } else if (statusA && !statusB) {
          return 1
        } else if (!statusA && statusB) {
          return -1
        }
      },
      render: (record) => (
        <div>
          <Tag color={setColor(record.verifyId)}>{record.verifyId ? 'ได้รับการยืนยันตัวตน' : 'ยังไม่ได้รับการยืนยันตัวตน'}</Tag>
        </div>
      ),
    },
    {
      title: 'ข้อมูลอุปกรณ์',
      key: 'verified',
      width: 200,
      align: 'left',
      render: (record) =>
        record.deviceInfo ? (
          <>
            <Text>
              {`เวอร์ชั่น: `}
              <Tag color="blue">{record.deviceInfo.version}</Tag>
            </Text>
            <Text>
              {`ยี่ห้อ:   `}
              <Tag color="blue">{record.deviceInfo.manufacturer}</Tag>
            </Text>
            <Text>
              {`รุ่น: `}
              <Tag color="blue">{record.deviceInfo.model}</Tag>
            </Text>
          </>
        ) : (
          <Text> ยังไม่ระบุข้อมูล</Text>
        ),
    },
  ]

  const columnsAlert = [
    ...columns,
    {
      title: 'ปัญหา',
      key: 'problems',
      width: 100,
      render: (record) => (
        <Button
          type="primary"
          onClick={() => {
            const problems = record.kycProblem ? record.kycProblem.problems : '-'
            setNoteProblems(problems)
            setVisibleKycProblemInfoModal(true)
          }}
        >
          ปัญหา
        </Button>
      ),
    },
  ]

  useEffect(() => {
    if (state.currentTab == 'problemsList') {
      setShowColumnByTab(state.currentTab)
    } else {
      setShowColumnByTab(false)
    }
  }, [state.currentTab])

  const onSearch = (text, data) => {
    let dataForFilter = filterDataByTabForSearch(data)
    const dataFiltered = dataForFilter.filter((data) => {
      const fullname = data.firstname && data.lastname ? `${data.firstname} ${data.lastname}` : ''
      return (
        (data.cId && data.cId.includes(text)) ||
        (data.firstname && data.firstname.includes(text)) ||
        (data.lastname && data.lastname.includes(text)) ||
        (data.firstname && data.lastname && fullname.includes(text)) ||
        (data.mobileNumber && data.mobileNumber.includes(text))
      )
    })
    setSearchText(text.length ? text : false)
    setDataByTab(text.length ? dataFiltered : false)
  }

  const filterDataByTabForSearch = (dataToUpdate) => {
    switch (state.currentTab) {
      case 'allUserList':
        return dataToUpdate.allUserListData ? dataToUpdate.allUserListData : allUserListData
      case 'kycWaitingList':
        return dataToUpdate.kycWaitingListData ? dataToUpdate.kycWaitingListData : kycWaitingListData
      case 'problemsList':
        return dataToUpdate.problemsListData ? dataToUpdate.problemsListData : problemsListData
      case 'requestToDeleteList':
        return dataToUpdate.problemsListData ? dataToUpdate.requestToDeleteListData : requestToDeleteListData
      default:
        return state.dataSource
    }
  }

  const setDataByTab = (dataFiltered) => {
    switch (state.currentTab) {
      case 'allUserList':
        setFilterAllUserListData(dataFiltered)
        break
      case 'kycWaitingList':
        setFilterKycWaitingListData(dataFiltered)
        break
      case 'problemsList':
        setFilterProblemsListData(dataFiltered)
        break
      case 'problemsList':
        setFilterRequestToDeleteListData(dataFiltered)
        break
      default:
        setFilterAllUserListData(dataFiltered)
        break
    }
  }

  return (
    <Formik initialValues={{ filter: '' }}>
      {(props) => {
        const {} = props
        return (
          <Container>
            <HeaderWrapper>
              <FileOutlined />
              <Text bold>รายชื่อผู้ใช้</Text>
              {state.roles && state.roles.includes('callCenter') ? <Text> ({state.time})</Text> : null}
              <Button className="ml-2" onClick={() => refreshData()}>
                รีโหลด
              </Button>
              <Search onSearch={onSearch} style={{ width: 200, marginLeft: 'auto' }} />
            </HeaderWrapper>
            <ContentTab
              columns={showColumnByTab && showColumnByTab.includes('problemsList') ? columnsAlert : columns}
              data={{
                allUserList: filterAllUserListData || allUserListData,
                kycWaitingList: filterKycWaitingListData || kycWaitingListData,
                problemsList: filterProblemsListData || problemsListData,
                requestToDeleteList: filterRequestToDeleteListData || requestToDeleteListData,
              }}
              role={state.roles[0]}
              loading={loading}
              setTab={(tab) => setCurrentTab(tab)}
              state={state}
              {...rest}
            ></ContentTab>
            {/* <Table scroll={{ x: 'max-content' }} columns={columns} dataSource={filterAllUserListData || allUserListData} loading={loading} {...rest} /> */}
            <Modal
              title="รายละเอียดผู้ป่วย"
              visible={visiblePatientProfile}
              style={{ textAlign: 'center' }}
              onCancel={() => {
                setVisiblePatientProfile(false)
              }}
              onOk={() => {
                triggerUpdateDataWithPatientInfo(userId)
                checkUserDetail(userId)
                verifyStatus(patientId, true)
                fetchUserFcmToken(userId, 'คุณได้รับการยืนยันตัวตนจากเจ้าหน้าที่โรงพยาบาลเรียบร้อยแล้ว สามารถนัดแพทย์สำหรับการทำโทรเวชกรรมได้เลย', null, 'Profile')
                setVisiblePatientProfile(false)
              }}
            >
              {imageLoading ? (
                <Spin></Spin>
              ) : (
                <Row>
                  <Image.PreviewGroup>
                    {profileImage.map((image) => (
                      <Col span={12}>
                        <ImageKYC src={image} alt="" />
                      </Col>
                    ))}
                  </Image.PreviewGroup>
                </Row>
              )}
            </Modal>
            <Modal
              visible={visibleReportProblemModal}
              onOk={() => {
                setVisibleReportProblemModal(false)
              }}
              onCancel={() => {
                setVisibleReportProblemModal(false)
              }}
              title="แจ้งปัญหา"
              footer={null}
            >
              <ReportProblemForm labelTitle="โปรดระบุปัญหาที่พบในการ KYC" onSubmit={changeKycProblemStatus}></ReportProblemForm>
            </Modal>
            <Modal
              visible={visibleKycProblemInfoModal}
              onOk={() => {
                setVisibleKycProblemInfoModal(false)
              }}
              onCancel={() => {
                setVisibleKycProblemInfoModal(false)
              }}
              title="รายละเอียดปัญหา"
              footer={null}
            >
              <p>{noteProblems}</p>
            </Modal>
            <Modal
              title="หมายเลข PIN 6 หลัก คือ"
              visible={modalPin}
              style={{ textAlign: 'center' }}
              onOk={() => {
                setModalPin(false)
              }}
              onCancel={() => {
                setModalPin(false)
              }}
            >
              <Row>
                <Col style={{ fontSize: 28 }} span={24}>
                  {pinCode}
                </Col>
              </Row>
            </Modal>
          </Container>
        )
      }}
    </Formik>
  )
}
export default UserListTable
