import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Spin, Card, Row, Tabs, Table } from 'antd'
const DataCard = styled(Card)`
  height: 100%;
  width: 400px;
  min-width: 250px;
  cursor: pointer;
  border-radius: 5px;
  border: .5px solid #d3d3d3;  
  &:hover {
    transform: scale(1.08);
    z-index: 999;
  }
  transition: 0.3s;
`
const View = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h5`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`

const Description = styled.p`
  font-size: 2.4rem;
  color: #6e6e6e;
`
const ReportSubmission = (props) => {
    const { submissions, surveys } = props
    const [summary, setSummary] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [columns, setColumns] = useState([])
    const [dataSource, setDataSource] = useState([])
    const [columnsAnswer, setColumnsAnswer] = useState([])
    const [answerDataSource, setAnswerDataSource] = useState([])
    const [tab, setTab] = useState('0')

    useEffect(() => {
        if (surveys.length > 0 && submissions.length > 0) {
            countSubmission()
            querySubmission(tab)
        }
    }, [submissions, surveys])

    useEffect(() => {
        if (dataSource.length === 0) {
            setAnswerDataSource([])
            setColumnsAnswer([])
        }
        if (dataSource.length > 0) {
            querySubmissionByMonth()
        }
    }, [dataSource])

    const querySubmissionByMonth = () => {
        let answerMap = {};
        const MONTH_NAMES = [
            'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน',
            'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม',
            'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
        ];
        const THAI_YEAR_OFFSET = 543;
        dataSource.forEach((submission) => {
            let count = {};
            submission.forEach((sub) => {
                if (!(sub.answer >= 0 && sub.answer < 6)) {
                    const splitAnswer = sub.answer.split(', ')
                    splitAnswer.reduce((acc, choice) => {
                        count[choice] = (count[choice] || 0) + 1;
                        return acc;
                    }, {});
                }
                const submissionYear = new Date(sub.createdAt).getFullYear();
                const submissionMonth = new Date(sub.createdAt).getMonth() + 1;
                const questionId = sub.questionId;
                const questionTitle = sub.questionTitle

                if (!answerMap[questionId]) {
                    answerMap[questionId] = {};
                }

                if (!answerMap[questionId][submissionYear]) {
                    answerMap[questionId][submissionYear] = {};
                }

                if (!answerMap[questionId][submissionYear][submissionMonth]) {
                    answerMap[questionId][submissionYear][submissionMonth] = {
                        answer: 0,
                        maximum: 0,
                        questionTitle,
                        count: 0
                    };
                }

                if (sub.answer.length === 1) {
                    answerMap[questionId][submissionYear][submissionMonth].answer += parseInt(sub.answer);
                    answerMap[questionId][submissionYear][submissionMonth].maximum += 5;
                    answerMap[questionId][submissionYear][submissionMonth].count += 1;
                }
            });
        });


        const questionIds = Object.keys(answerMap);
        const years = new Set();
        const months = new Set();

        questionIds.forEach((questionId) => {
            Object.keys(answerMap[questionId]).forEach((year) => {
                years.add(year);
                Object.keys(answerMap[questionId][year]).forEach((month) => {
                    months.add(month);
                });
            });
        });

        const columns = [];
        columns.push({
            title: 'Question',
            dataIndex: 'questionTitle',
            key: 'questionTitle',
            fixed: 'left',
        });

        years.forEach((year) => {
            months.forEach((month) => {
                const columnKey = `${year}-${month}`;
                columns.push({
                    title: `${MONTH_NAMES[month - 1]} ${parseInt(year) + THAI_YEAR_OFFSET}`,
                    dataIndex: columnKey,
                    key: columnKey,
                });
            });
        });
        const dataSourceQuery = questionIds.map((questionId) => {
            const dataRow = {
                questionId,
            };

            years.forEach((year) => {
                months.forEach((month) => {
                    const cellValue = answerMap[questionId]?.[year]?.[month]?.answer || 0;
                    const maxValue = answerMap[questionId]?.[year]?.[month]?.maximum || 1;
                    const countValue = answerMap[questionId]?.[year]?.[month]?.count || 0;
                    const percentage = `จำนวนคน: ${countValue} และ จำนวนคะแนน ${cellValue}/${maxValue} (${parseFloat((cellValue / maxValue) * 100).toFixed(2)}%)`
                    const cellKey = `${year}-${month}`;
                    dataRow[cellKey] = percentage;
                    dataRow["questionTitle"] = answerMap[questionId]?.[year]?.[month]?.questionTitle
                });
            });

            return dataRow;
        });
        setColumnsAnswer(columns)
        setAnswerDataSource(dataSourceQuery)
    }

    const countSubmission = () => {
        let count = {}
        submissions.forEach((submission) => {
            const surveyId = submission.survey.id;
            if (!count[surveyId]) {
                count[surveyId] = 1
            } else {
                count[surveyId]++;
            }
        })
        setSummary(count)
    }

    const querySubmission = (key) => {
        let filterColumns = []
        let filterDataSource = []
        const parseKey = parseInt(key)
        const survey = surveys[parseKey]
        const filterSubmissions = submissions.filter((submission) => submission.surveyId === survey.id)
        if (filterSubmissions.length > 0) {
            filterColumns = filterSubmissions[0].survey.questions.map((question) => ({
                title: question.text,
                key: "question",
                align: 'center',
                id: question.id,
                render: (record) => {
                    const filterRecord = record.filter((rec) => rec.questionId === question.id)
                    return filterRecord.length > 0 ? filterRecord[0].answer : ''
                }
            }))
            filterDataSource = filterSubmissions.map((filterSubmission) => {
                return filterSubmission.answers.map((answer) => {
                    const selectedIds = answer.selectedOption.map((selected) => selected.id);
                    const answers = answer.question.options.filter((option) => selectedIds.includes(option.id)).map((option) => option.label)
                    return {
                        questionTitle: answer.question.text,
                        questionId: answer.questionId,
                        answer: answers.join(', '),
                        createdAt: filterSubmission.createdAt
                    }
                })
            })
        }
        setColumns(filterColumns)
        setDataSource(filterDataSource)
        setTab(key)
        setIsLoading(false)
    }


    return (
        <div className="h-100">
            {isLoading ? (
                <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                    <Spin className="text-center" />
                </div>
            ) : (
                <Row>
                    {
                        surveys.map((survey, index) => (
                            <DataCard key={index} className='ml-3 mr-3 mt-2 mb-2 pt-1 pb-1' bodyStyle={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <View>
                                    <Title className='pb-1'>{survey.title}</Title>
                                    <View className='pt-2' style={{ flexDirection: 'row', alignItems: 'baseline', justifyContent: 'center' }}>
                                        <Description style={{ margin: 0 }}>{summary[survey.id] || 0}</Description>
                                        <Description style={{ fontSize: 14 }}>ฟอร์ม</Description>
                                    </View>
                                </View>
                            </DataCard>
                        ))
                    }
                    <Tabs style={{ width: '100%' }} defaultActiveKey='0' onChange={(key) => querySubmission(key)}>
                        {surveys.map((survey, surveyIndex) => {
                            return (
                                <Tabs.TabPane tab={survey.title} key={surveyIndex}>
                                    <Table columns={columns} dataSource={dataSource} scroll={{ x: 'max-content' }} />
                                    <Table columns={columnsAnswer} dataSource={answerDataSource} scroll={{ x: 'max-content' }} />
                                </Tabs.TabPane>
                            )
                        })}
                    </Tabs>

                </Row>
            )}
        </div>
    )
}

export default ReportSubmission
