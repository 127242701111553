import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';
import styled from 'styled-components'
import { Spin, Card, Row, Table, Tabs } from 'antd'
const DataCard = styled(Card)`
  height: 100%;
  width: '100%';
  min-width: 250px;
  cursor: pointer;
  border-radius: 5px;
  border: .5px solid #d3d3d3;  
  &:hover {
    transform: scale(1.08);
    z-index: 999;
  }
  transition: 0.3s;
`

const Text = styled.p`
  font-size: 14px;
  color: #2b2b2b;
`
const View = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h5`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`

const Description = styled.p`
  font-size: 2.4rem;
  color: #6e6e6e;
`

const ReportRegistration = (props) => {
  const { users } = props
  const [series, setSeries] = useState([])
  const [options, setOptions] = useState({})
  const [countUser, setCountUser] = useState(0);
  const [counts, setCounts] = useState([])
  const [summaryOfYears, setSummaryOfYears] = useState({})
  const [isLoading, setIsLoading] = useState(true);
  const THAI_YEAR_OFFSET = 543;
  const MONTH_NAMES = [
    'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน',
    'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม',
    'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
  ];

  const columns = [
    {
      title: 'ปี พ.ศ.',
      dataIndex: 'year',
      key: 'year',
    },
    ...MONTH_NAMES.map((month) => ({
      title: month,
      dataIndex: month,
      key: month,
    })),
  ]



  useEffect(() => {
    if (users?.length > 0) {
      monthlyCounts()
    }
  }, [users])

  const monthlyCounts = async () => {


    const countMonths = {};
    const countYears = {}
    let countUserByDateNull = 0
    let countUserByDate = 0

    users.forEach((user) => {
      const createdAt = new Date(user.created_at);

      if (user.created_at === null) {
        countUserByDateNull++
      } else {

        const year = createdAt.getFullYear() + THAI_YEAR_OFFSET;
        const month = MONTH_NAMES[createdAt.getMonth()];

        if (createdAt.getMonth() >= 9) {
          const newYear = year + 1
          if (!countYears[newYear]) {
            countYears[newYear] = 1
          } else {
            countYears[newYear]++
          }
        }
        if (createdAt.getMonth() <= 8) {
          const prevYear = year - 1
          if (!countYears[prevYear]) {
            countYears[prevYear] = 1
          } else {
            countYears[prevYear]++
          }
        }

        if (!countMonths[year]) {
          countMonths[year] = {};
        }

        if (!countMonths[year][month]) {
          countMonths[year][month] = 1;
        } else {
          countMonths[year][month]++;
        }
        countUserByDate++
      }

    });
    const allYear = Object.keys(countYears);
    const summary = allYear.map((year) => ({
      name: year,
      data: countYears[year]
    }))
    const years = Object.keys(countMonths);
    const mapSeries = years.map((year) => ({
      name: year,
      data: MONTH_NAMES.map((month) => countMonths[year][month] || 0),
    }));

    const mapOptions = {
      chart: {
        id: 'user-creation-chart',
        type: 'bar',
      },
      xaxis: {
        categories: MONTH_NAMES,
      },
    };

    const data = years.map((year) => {
      const yearData = {
        key: year,
        year,
      };

      MONTH_NAMES.forEach((month) => {
        yearData[month] = countMonths[year]?.[month] || 0
      });

      return yearData;
    });
    const dataSource = data.sort((a, b) => b.year - a.year);

    setCounts(dataSource)
    setSummaryOfYears(summary)
    setCountUser(countUserByDateNull + countUserByDate)
    setSeries(mapSeries);
    setOptions(mapOptions);
    setIsLoading(false);
  }

  return (
    <div className="h-100">
      {isLoading ? (
        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
          <Spin className="text-center" />
        </div>
      ) : (
        <>
          <div className="w-100 p-4">
            <Row>
              <DataCard className='ml-3 mr-3 mt-2 mb-2 pt-1 pb-1' bodyStyle={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <View>
                  <Title className='pb-1'>จำนวนผู้ใช้งาน</Title>
                  <View className='pt-2' style={{ flexDirection: 'row', alignItems: 'baseline', justifyContent: 'center' }}>
                    <Description style={{ margin: 0 }}>{countUser}</Description>
                    <Description style={{ fontSize: 14 }}>คน</Description>
                  </View>
                </View>
              </DataCard>
            </Row>
            <Tabs defaultActiveKey='0' >
              <Tabs.TabPane key='0' tab='กราฟ'>
                <Chart options={options} series={series} type="line" height={350} />
              </Tabs.TabPane>
              <Tabs.TabPane key='1' tab='ตาราง'>
                <Row className="ml-3 mr-3 mt-2 mb-2 pt-1 pb-1">
                  <Table dataSource={counts} columns={columns} scroll={{ x: 'max-content' }} pagination={false} />
                </Row>
              </Tabs.TabPane>
            </Tabs>

            <Row>
              {
                summaryOfYears.map((summary, index) => (
                  <DataCard key={index} className='ml-3 mr-3 mt-2 mb-2 pt-1 pb-1' bodyStyle={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <View>
                      <Title className='pb-1'>{summary.name}</Title>
                      <View className='pt-2' style={{ flexDirection: 'row', alignItems: 'baseline', justifyContent: 'center' }}>
                        <Description style={{ margin: 0 }}>{summary.data}</Description>
                        <Description style={{ fontSize: 14 }}>คน</Description>
                      </View>
                    </View>
                  </DataCard>
                ))
              }
            </Row>
          </div>
        </>
      )}
    </div>
  )
}

export default ReportRegistration
