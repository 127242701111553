import React from 'react'
import { Row, Col, Popconfirm } from 'antd'
import { CloseOutlined, VideoCameraOutlined, MessageOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { CloseIcon, CardMiddle, CardCenter, CardCalling, Container, TitleName, ChatButton, VideoCallButton, DeclineButton } from './styles'

const RoomListDetail = ({ onSetRoomList, roomId, patient, handleSubmitCall, handleDeclineCall, onSetChat, isLoading, onSetPatient, practitionerRole, setIsTestVideoCall, isTestVideoCall, checkInternet }) => {
  const convertImage = (base64) => {
    if (base64) {
      return `data:image/png;base64,${base64}`
    }
    return 'https://thumbs.dreamstime.com/b/default-avatar-photo-placeholder-profile-picture-default-avatar-photo-placeholder-profile-picture-eps-file-easy-to-edit-125707135.jpg'
  }

  const checkTitleName = (patient) => {
    let name = 'ไม่พบข้อมูลลูกค้า'
    if (patient.fullname && patient.fullname.firstName) {
      const { title, firstName, lastName } = patient.fullname
      name = `${title} ${firstName} ${lastName}`
    } else if (patient.user && patient.user.firstname) {
      const { firstname, lastname } = patient.user
      name = `${firstname} ${lastname}`
    }
    return name
  }

  return (
    <div style={{ height: '100%' }}>
      <CloseIcon>
        <CloseOutlined style={{ cursor: 'pointer' }} onClick={() => onSetRoomList(true)} />
      </CloseIcon>
      <CardMiddle>
        <CardCenter>
          <CardCalling>
            <img src={convertImage(patient.imageBase64)} className="rounded-circle" style={{ width: '70%', boxShadow: '0px 0px 5px #e4e4e4' }} />
            <TitleName type="secondary" level={3}>
              {checkTitleName(patient)}
            </TitleName>
            <Row gutter={12} justify="center">
              <Col className="pb-1">
                <VideoCallButton
                  loading={isLoading}
                  onClick={() => {
                    handleSubmitCall(roomId)
                    if (practitionerRole === 'callCenter') {
                      setIsTestVideoCall(true)
                    }
                  }}
                  icon={<VideoCameraOutlined />}
                  size="large"
                >
                  {practitionerRole === 'callCenter' ? 'ทดสอบการโทร' : 'โทร'}
                </VideoCallButton>
              </Col>
              {practitionerRole !== 'callCenter' && (
                <Col className="pb-1">
                  <ChatButton
                    icon={<MessageOutlined />}
                    size="large"
                    onClick={() => {
                      onSetChat(true)
                    }}
                  >
                    แชท
                  </ChatButton>
                </Col>
              )}
            </Row>
            <Popconfirm
              title="โปรดระวัง! กรุณาใช้กับห้องวิดีโอคอลที่ค้างเท่านั้น"
              onConfirm={() => {
                handleDeclineCall(roomId)
                onSetPatient(false)
                onSetRoomList(true)
              }}
              okText="เคลียร์ห้องค้าง"
              cancelText="ยกเลิก"
            >
              <DeclineButton icon={<CloseCircleOutlined />} size="large">
                เคลียร์ห้องค้าง
              </DeclineButton>
            </Popconfirm>
          </CardCalling>
        </CardCenter>
      </CardMiddle>
    </div>
  )
}

export default RoomListDetail
