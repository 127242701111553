import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Button, Row, Col, Modal, Input, message, Spin, Select, Tag, Card } from 'antd'
import firebase from 'firebase'
import { AMBULANCE_STATUS, AMBULANCE_COLOR } from '../../../constant'
import ModalPatientHistory from '../../Modal/PatientHistory'
import ButtonAction from './widgets/ButtonAction'
import { CardProfile } from '../styles'
import RenderDateTime from './widgets/RenderDateTime'
import { get, isNaN, isNumber, orderBy } from 'lodash'
import ConvertDateTimeTH from '../../ConvertDateTimeTH'
import http from '../../../services/httpService'
import moment from 'moment'
import { FaMapMarker } from 'react-icons/fa'
import './mapmarker.css'
const { TextArea } = Input

@inject('UserInfo')
@inject('AppUserIdentity')
@inject('EmsForm')
@inject('DashboardData')
@observer
class Emergency extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      emergencys: [],
      status: 'patientWaiting',
      visibleModalCancel: false,
      key: false,
      patient: false,
      visibleModalHistory: false,
      isLoading: false,
      hieData: '',
      patientData: '',
      medicalBenefit: '',
      loading: true,
      patientImage: '',
      visibleModalForword: false,
      comment: '',
      ambulanceSelected: '',
      errorBorder: '',
      customAddData: {},
      id: false,
    }
  }

  fetchUserInfos = async (key) => {
    const { UserInfo } = this.props
    const selectedObject = this.state.emergencys.find((item) => item.key === key)
    const patientId = get(selectedObject, 'patientId', '')
    if (patientId) {
      const response = await UserInfo.fetchUserInfoByPatientId(patientId)
      const cId = get(response, 'cId', '')
      if (cId) {
        const userInfo = await UserInfo.fecthUserInfoByCId(cId)
        const fullName = get(selectedObject, 'patientName', '').split(' ')
        return {
          demographicData: {
            cid: get(userInfo, 'cid', ''),
            titleName: get(userInfo, 'fullname.title', ''),
            firstName: get(fullName, '[0]', ''),
            lastName: get(fullName, '[1]', ''),
            tel: get(selectedObject, 'number', ''),
            age: this.getAgeFromBirthDate(get(userInfo, 'DOB', '')),
            gender: get(userInfo, 'sexName', ''),
            birthDate: get(userInfo, 'DOB', ''),
            nationality: get(userInfo, 'nationality', ''),
          },
          transferData: {
            assessment: {
              hn: get(response, 'hnId', ''),
            },
          },
          location: get(selectedObject, 'geocoding', null),
        }
      }
    }
    return { demographicData: null, transferData: null, location: null }
  }

  getAgeFromBirthDate = (dob) => {
    const dateOfBirth = moment(dob).startOf('day')
    const today = moment().startOf('day')
    const diffYear = today.diff(dateOfBirth, 'year')
    return !isNaN(diffYear) && isNumber(diffYear) ? diffYear.toString() : ''
  }

  fetchMedicalBenefit = (id) => {
    const { UserInfo } = this.props
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const everOmaId = await this.fetchAppUserIdentity(id)
        if (everOmaId) {
          const response = await UserInfo.checkLatestMedicalBenefit(everOmaId)
          if (response && response.PID) {
            this.setState({
              medicalBenefit: response,
              isLoading: false,
            })
          } else {
            this.setState({
              medicalBenefit: false,
              isLoading: false,
            })
          }
        } else {
          this.setState({
            medicalBenefit: false,
            isLoading: false,
          })
        }
      },
    )
  }

  fetchPatient = (id) => {
    const { AppUserIdentity, UserInfo } = this.props
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const everOmaId = await this.fetchAppUserIdentity(id)
        if (everOmaId) {
          const resHie = await UserInfo.checkUserDetailByVerifiedFromHIE(everOmaId)
          const resPatient = await UserInfo.fetchUserInfoByPatientId(everOmaId)
          this.setState({
            hieData: resHie,
            patientData: resPatient,
            patientImage: resPatient.img,
          })
        }
        this.setState({
          isLoading: false,
        })
      },
    )
  }

  filterStatusFirebase = async () => {
    const { ems, UserInfo } = this.props
    const data = []
    await Promise.all(
      ems.map(async (emsMap) => {
        data.push({ ...emsMap })
      }),
    )
    this.setState({
      emergencys: data,
      loading: false,
    })
  }

  fetchAppUserIdentity = async (id) => {
    const { AppUserIdentity } = this.props
    const response = await AppUserIdentity.fetchAppUserIdentity(id)
    if (response && response.length) {
      return response[response.length - 1].everOmaId
    }
    return false
  }

  componentDidMount() {
    this.filterStatusFirebase()
  }

  componentDidUpdate(prevProps) {
    //if (prevProps.ems !== this.props.ems || prevProps.status !== this.props.status) {
    if (prevProps.ems !== this.props.ems) {
      this.filterStatusFirebase()
    }
  }
  updateEmsCarStatusGoOut = (ambulanceNo, status, id, licensePlate, operationDateTime) => {
    const availability = ['รถพยาบาลไม่พร้อม'].includes(status) ? false : true
    if (ambulanceNo) {
      localStorage.setItem('ambulanceNo', ambulanceNo)
    } else ambulanceNo = localStorage.getItem('ambulanceNo')
    if (ambulanceNo) {
      firebase
        .database()
        .ref(`ambulance/${ambulanceNo}`)
        .update({ note: status, availability })
    }
    if (id) {
      this.updateNewEmsFormCustomAdd({
        id: id,
        responseData: {
          vehicle: `${ambulanceNo}`,
          operationDateTime,
        },
      })
    }
  }

  updateEmsPositionAlert = (key, status, ambulanceNo) => {
    if (!ambulanceNo) {
      firebase
        .database()
        .ref(`emsPositionAlert/${Number(key)}`)
        .update({ status })
      message.success('เปลี่ยนสถานะสำเร็จ')
    } else {
      firebase
        .database()
        .ref(`emsPositionAlert/${Number(key)}`)
        .update({ status, ambulanceNo })
    }
    this.setState({ key: false })
  }

  updateEmsIdToFirebase = (key, id, operationDateTime) => {
    firebase
      .database()
      .ref(`emsPositionAlert/${Number(key)}`)
      .update({ id, operationDateTime })
  }

  updateStatusEms = async (key, status, id) => {
    console.log('helloo ', key, status, id)
    const { user, EmsForm } = this.props
    if (status === 'actionWaiting') {
      const { demographicData, location, transferData } = await this.fetchUserInfos(key)
      const { data } = await EmsForm.createEmsForm(user.id, status, key, { demographicData, location, transferData }, 'vajira@home', moment(new Date()).toISOString())
      this.updateEmsIdToFirebase(key, data.id, moment(new Date()).toISOString())
    } else if (status === 'cancelled' || status === 'pass') {
      let comment = this.state.comment
      let commentData = { comment }
      const { demographicData, location, transferData } = await this.fetchUserInfos(key)
      const { data } = await EmsForm.createEmsForm(
        user.id,
        status,
        key,
        {
          data: commentData,
          demographicData,
          location,
          transferData,
        },
        'vajira@home',
        moment(new Date()).toISOString(),
      )
      this.updateEmsIdToFirebase(key, data.id, moment(new Date()).toISOString())
    } else if (!id) {
      this.findEmsFormAndUpdate(key, status)
    } else if (id) {
      const emsData = await EmsForm.findEmsFormById(id)
      const stateTimeStamp = emsData[0].stateTimeStamp
      stateTimeStamp.push({ status, date: new Date().toLocaleString() })
      this.updateNewEmsFormCustomAdd({
        id,
        status,
        stateTimeStamp,
        data: {
          comment: this.state?.comment || '',
        },
      })
    }
  }

  updateNewEmsFormCustomAdd = async (values) => {
    try {
      const response = await http.patch('/emsForms', values)
      console.log('response ===', response)
      console.log('values ===', values)
      if (response && response.status === 200) {
        message.success('อัพเดทข้อมูลเรียบร้อยแล้ว')
        this.setState({ comment: '' })
        return true
      }
    } catch (error) {
      console.log('faile ===', error)
      console.log('values ===', values)
      message.error('เกิดข้อผิดพลาดในการยกเลิก')
      return false
    }
  }

  fetchAppUserIdentity = async (id) => {
    const { AppUserIdentity } = this.props
    const response = await AppUserIdentity.fetchAppUserIdentity(id)
    if (response && response.length) {
      return response[response.length - 1].everOmaId
    }
    return false
  }

  sendEmsData = async (userId) => {
    const { EmsForm, setDetail } = this.props
    const emsForms = await EmsForm.findEmsFormByUserId(userId)
    const emsForm = emsForms.filter((emsForm) => emsForm.status !== 'noted' && emsForm.status !== 'cancelled')
    const latestEmsForm = emsForm.pop()
    setDetail(latestEmsForm)
  }

  findEmsFormAndUpdate = async (userId, status) => {
    const { EmsForm } = this.props
    const emsForms = await EmsForm.findEmsFormByUserId(userId)
    const emsForm = emsForms.filter((emsForm) => emsForm.status !== 'completed' && emsForm.status !== 'cancelled')
    const latestEmsForm = emsForm.pop()
    const stateTimeStamps = latestEmsForm.stateTimeStamp
    stateTimeStamps.push({ status, date: new Date().toLocaleString() })
    const data = {
      status,
      stateTimeStamp: stateTimeStamps,
    }
    await EmsForm.updateEmsForm(latestEmsForm.id, data)
  }

  renderButtonHIE = (index, patient) => {
    return (
      <div
        className="text-center"
        key={index}
        style={{
          borderRadius: 20,
          color: 'white',
          padding: 12,
        }}
      >
        <button
          style={{
            border: 'none',
            background: '#005c8a',
            borderRadius: 27,
            color: 'white',
            padding: 12,
            lineHeight: '15px',
            width: '-webkit-fill-available',
          }}
          onClick={() => {
            this.fetchPatient(patient.key)
            this.fetchMedicalBenefit(patient.key)
            this.setState({
              visibleModalHistory: true,
            })
          }}
        >
          คลิกเพื่อดูข้อมูลผู้ป่วยอย่างละเอียด
        </button>
      </div>
    )
  }
  renderDropDownEmergencyCar = (emsCar, index, tab) => {
    const { ambulance } = this.props
    const ambulanceList = [...ambulance.filter((item) => [25, 26, 27, 29].includes(item.no)), ...ambulance.filter((item) => ![25, 26, 27, 29].includes(item.no))]

    return (
      <div key={emsCar + index} style={{ padding: 10 }}>
        <style>{`
          .select-status-ambulance .ant-select-selector {
              border-color: ${this.state.errorBorder ? this.state.errorBorder + ' !important' : ''};
          }
        `}</style>
        <Select
          key={emsCar + index}
          style={{ width: '100%' }}
          onChange={(value, children) => {
            this.setState({ licensePlate: children.label, ambulanceSelected: value, errorBorder: '' })
          }}
          defaultValue={emsCar}
          className="select-status-ambulance"
          placeholder="เลือกรถปฏิบัตการณ์"
          disabled={emsCar ? true : false}
        >
          {ambulanceList.map((item, index) => {
            if (item.note === 'พร้อมใช้งาน' && item.availability && tab != 4) {
              return (
                <Option key={item.no} value={item.key} style={{ backgroundColor: index % 2 === 0 ? '#d2d2d225' : 'white' }}>
                  <Row>
                    <Col span={4} className="text-center">
                      {item.no}
                    </Col>
                    <Col span={8} className="text-center">
                      {item.licensePlate}
                    </Col>
                    {!emsCar && (
                      <Col span={12} className="text-center">
                        <Tag color={'green'}>{item.note}</Tag>
                      </Col>
                    )}
                  </Row>
                </Option>
              )
            } else if (tab == 4) {
              return (
                <Option key={item.no} value={item.key} style={{ backgroundColor: index % 2 === 0 ? '#d2d2d225' : 'white' }}>
                  <Row>
                    <Col span={4} className="text-center">
                      {item.no}
                    </Col>
                    <Col span={8} className="text-center">
                      {item.licensePlate}
                    </Col>
                    {!emsCar && (
                      <Col span={12} className="text-center">
                        <Tag color={'green'}>{item.note}</Tag>
                      </Col>
                    )}
                  </Row>
                </Option>
              )
            }
          })}
        </Select>
      </div>
    )
  }

  styler = () => (
    <style>{`
      .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
          color: rgb(0 0 0 / 60%);
          background: #f0f0f0;
          cursor: not-allowed;
      }
    `}</style>
  )

  render() {
    const { patient, emergencys, visibleModalCancel, key, visibleModalForword, id, ambulanceNo } = this.state
    const { setCurrentPosition, status, tab, updatePatient, setKey, emsForm, setFinishedAdd } = this.props

    return (
      <div>
        {this.state.loading ? (
          <div className="w-100 d-flex justify-content-center mt-3">
            <Spin />
          </div>
        ) : tab != 4 ? (
          orderBy(emergencys, (e) => new Date(e.date), ['desc']).map(
            (emergency, index) =>
              status.includes(emergency.status) && (
                <Card
                  type="inner"
                  key={index}
                  title={<RenderDateTime date={emergency.date} />}
                  extra={<Tag color={AMBULANCE_COLOR[emergency.status] || 'black'}>{AMBULANCE_STATUS[emergency.status]}</Tag>}
                  size="small"
                  className="mb-2"
                  onClick={() => console.log('emergency :', emergency)}
                >
                  <Row style={{ paddingBottom: 10 }}>
                    <Col span={24}>
                      <span style={{ fontWeight: 'bold', fontSize: 16 }}>วันเวลาที่แจ้งเหตุ : </span>
                      <span style={{ fontSize: 16 }}>
                        <ConvertDateTimeTH dateTime={emergency.date} />
                      </span>
                    </Col>
                    <Col span={24}>
                      <span style={{ fontWeight: 'bold', fontSize: 16 }}>ชื่อผู้ป่วย : </span>
                      <span style={{ fontSize: 16 }}> {emergency.patientName ? `${emergency.patientName} ` : ''}</span>
                    </Col>
                    <Col span={24}>
                      <span style={{ fontWeight: 'bold', fontSize: 16 }}>เบอร์โทรศัพท์สำหรับติดต่อกลับ : </span>
                      <span style={{ fontSize: 16 }}>{emergency.number}</span>
                    </Col>
                  </Row>
                  <Col xs={24} md={24} lg={24}>
                    <ButtonAction
                      tab={tab}
                      state={this.state}
                      setState={(obj) => this.setState(obj)}
                      setKey={setKey}
                      emergency={emergency}
                      sendEmsData={this.sendEmsData}
                      updatePatient={updatePatient}
                      updateEmsCarStatusGoOut={this.updateEmsCarStatusGoOut}
                      updateEmsPositionAlert={this.updateEmsPositionAlert}
                      updateStatusEms={this.updateStatusEms}
                      history={this.props.history}
                      setTabActiveKey={this.props.setTabActiveKey}
                    />
                  </Col>
                  {emergency.status === 'exitWaiting' ? this.renderDropDownEmergencyCar(null, index, tab) : null}
                  {this.renderButtonHIE(index, emergency)}
                  <CardProfile className="mt-2">
                    <div className="map-entry">
                      <div
                        className="map-marker"
                        data-label={emergency.markerLabel}
                        onClick={() => {
                          setCurrentPosition({ lat: emergency.lat, lng: emergency.lng })
                        }}
                      >
                        <FaMapMarker />
                      </div>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setCurrentPosition({ lat: emergency.lat, lng: emergency.lng })
                        }}
                      >
                        {get(emergency, 'geocoding.district', '') ? `เขต: ${get(emergency, 'geocoding.district', '').replace('Khet', '')}` : ''}
                        {get(emergency, 'geocoding.district', '') && <br />}
                        {emergency.lat}, {emergency.lng}
                      </div>
                      <Button onClick={() => window.open(`https://maps.google.com/?q=${emergency.lat},${emergency.lng}`)}>Google Map</Button>
                    </div>
                  </CardProfile>
                </Card>
              ),
          )
        ) : tab == 4 ? (
          emsForm.map(
            (item, index) =>
              status.includes(item.status) && (
                <Card type="inner" key={index} title={<RenderDateTime date={item.operationDateTime} />} extra={<Tag color={AMBULANCE_COLOR[item.status] || 'black'}>{AMBULANCE_STATUS[item.status]}</Tag>} size="small" className="mb-2">
                  {this.styler()}
                  <Row style={{ paddingBottom: 10 }}>
                    <Col span={24}>
                      <span style={{ fontWeight: 'bold', fontSize: 16 }}>ID : </span>
                      <span style={{ fontSize: 16 }}>{item.id}</span>
                    </Col>
                    <Col span={24}>
                      <span style={{ fontWeight: 'bold', fontSize: 16 }}>วันเวลาที่แจ้งเหตุ :</span>
                      <span style={{ fontSize: 16 }}>
                        <ConvertDateTimeTH dateTime={item.operationDateTime} />
                      </span>
                    </Col>
                    <Col span={24}>
                      <span style={{ fontWeight: 'bold', fontSize: 16 }}>คัดกรองอาการ : </span>
                      <span style={{ fontSize: 16 }}>{item.responseData ? item.responseData.notifiedSymptoms : ''}</span>
                    </Col>
                    <Col span={24}>
                      <span style={{ fontWeight: 'bold', fontSize: 16 }}>การรับแจ้ง : </span>
                      <span style={{ fontSize: 16 }}> {item.emsType === 'erawan' ? 'ศูนย์เอราวัณ' : '1554'}</span>
                    </Col>
                    <Col className="m-auto" span={6}>
                      <span style={{ fontWeight: 'bold', fontSize: 16 }}>รถพยาบาล :</span>
                    </Col>
                    <Col span={18}>{this.renderDropDownEmergencyCar(item.responseData ? item.responseData.vehicle : '', index, tab)}</Col>
                    <Col span={6}>
                      <span style={{ fontWeight: 'bold', fontSize: 16 }}>IDC :</span>
                    </Col>
                    <Col span={18}>{` ${item.responseData ? item.responseData.idcCode : ''}`}</Col>
                    <Col xs={24} md={24} lg={24}>
                      <ButtonAction
                        tab={tab}
                        state={this.state}
                        setState={(obj) => {
                          this.setState(obj)
                          this.setState({ comment: '' })
                        }}
                        setKey={setKey}
                        emergency={item}
                        history={this.props.history}
                        setTabActiveKey={this.props.setTabActiveKey}
                      />
                    </Col>
                  </Row>
                </Card>
              ),
          )
        ) : null}
        <ModalPatientHistory
          isLoading={this.state.isLoading}
          hieData={this.state.hieData}
          patient={this.state.patientData}
          visible={this.state.visibleModalHistory}
          medicalBenefit={this.state.medicalBenefit.PTTYPE_NAME}
          image={this.state.patientImage}
          setVisible={(visible) => this.setState({ visibleModalHistory: visible })}
        />
        <Modal
          visible={visibleModalCancel}
          onOk={() => this.setState({ visibleModalCancel: false })}
          onCancel={() => this.setState({ visibleModalCancel: false, comment: '' })}
          title="การยกเลิก"
          footer={
            <div>
              <Button
                onClick={() => {
                  this.setState({ visibleModalCancel: false, comment: '' })
                }}
                type="danger"
              >
                ยกเลิก
              </Button>
              <Button
                type="primary"
                disabled={!this.state.comment}
                onClick={async () => {
                  if (tab == 4) {
                    console.log('log ==', this.state.customAddData)
                    this.updateEmsCarStatusGoOut(this.state.customAddData.responseData ? this.state.customAddData.responseData.vehicle : '', 'พร้อมใช้งาน')
                    const resp = await this.updateNewEmsFormCustomAdd({
                      status: 'cancelled',
                      id: this.state.customAddData.id,
                      data: {
                        comment: this.state.comment,
                      },
                      stateTimeStamp: [
                        ...this.state.customAddData.stateTimeStamp,
                        {
                          status: 'cancelled',
                          date: new Date().toLocaleString(),
                        },
                      ],
                    })
                    setFinishedAdd(resp ? Math.floor(Math.random() * 100) : resp + `${Math.floor(Math.random() * 10)}`)
                    this.setState({ visibleModalCancel: false })
                  } else {
                    this.updateStatusEms(key, 'cancelled', id)
                    this.updateEmsPositionAlert(key, 'cancelled', 'false')
                    this.updateEmsCarStatusGoOut(ambulanceNo, 'พร้อมใช้งาน')
                    this.setState({ visibleModalCancel: false, comment: '' })
                  }
                }}
              >
                บันทึก
              </Button>
            </div>
          }
        >
          ท่านอยากยืนยันการยกเลิก case นี้หรือไม่? หากใช่ช่วยระบุเหตุผล
          <TextArea value={this.state.comment} rows={3} onChange={(e) => this.setState({ comment: e.target.value })} />
          {this.state.comment ? <p></p> : <p style={{ fontWeight: 'bold', fontSize: 10, color: 'red' }}>* โปรดกรอกเหตุผลการยกเลิก Case นี้!</p>}
        </Modal>

        <Modal
          visible={visibleModalForword}
          onOk={() => this.setState({ visibleModalForword: false })}
          onCancel={() => this.setState({ visibleModalForword: false, comment: '' })}
          title="การส่งต่อ"
          footer={
            <div>
              <Button
                onClick={() => {
                  this.setState({ visibleModalForword: false, comment: '' })
                }}
                type="danger"
              >
                ยกเลิก
              </Button>
              <Button
                type="primary"
                disabled={!this.state.comment}
                onClick={() => {
                  this.updateStatusEms(key, 'pass', id)
                  this.updateEmsPositionAlert(key, 'pass', 'false')
                  this.updateEmsCarStatusGoOut(ambulanceNo, 'พร้อมใช้งาน')
                  this.setState({ visibleModalForword: false, comment: '' })
                }}
              >
                บันทึก
              </Button>
            </div>
          }
        >
          ท่านอยากยืนยันการส่งต่อ case นี้หรือไม่? หากใช่ช่วยระบุเหตุผล
          <TextArea value={this.state.comment} rows={3} onChange={(e) => this.setState({ comment: e.target.value })} />
          {this.state.comment ? <p></p> : <p style={{ fontSize: 10, color: 'red' }}>* โปรดกรอกเหตุผลการส่งต่อ Case นี้!</p>}
        </Modal>
      </div>
    )
  }
}

Emergency.propTypes = {
  ems: PropTypes.array.isRequired,
}

export default Emergency
