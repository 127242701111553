import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Input, Tag } from 'antd'
import { Link } from 'react-router-dom'
import { UserOutlined, KeyOutlined } from '@ant-design/icons'
import Button from '../Button'
import logo from '../../images/Logo.png'

const Item = styled.div`
  margin-bottom: 20px;
  &:last-child {
    margin: 0;
  }
  ${(props) => {
    if (props.flex) {
      return `
        display: flex;
        justify-content: space-between;
      `
    }
    if (props.textRight) {
      return `
        text-align: right;
      `
    }
    return ''
  }}
`
const Container = styled.div`
  width: 350px;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 0;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  h5 {
    color: ${(props) => props.theme.primaryText};
    font-weight: bold;
  }
  p {
    color: ${(props) => props.theme.primaryText};
  }
  .forgot-password {
    font-size: 0.8125rem;
    color: ${(props) => props.theme.primaryButton};
    text-decoration: underline;
  }
`

const LoginForm = ({ email, password, error, onSubmit, onChange }) => { 

  React.useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        console.log('User pressed: ', event.key);
        event.preventDefault();
        onSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
  <Container>
    <img src={logo} alt="logo" width={100} height={100} />
    <h5>Vajira@Home</h5>
    <form onSubmit={onSubmit} style={{ padding: 20, width: '100%', marginTop: 20 }}>
      <Item>
        <h5>อีเมล</h5>
        <Input placeholder="กรอกอีเมล" prefix={<UserOutlined />} value={email} onChange={(e) => onChange({ email: e.target.value })} required />
      </Item>
      <Item>
        <h5>รหัสผ่าน</h5>
        <Input.Password placeholder="กรอกรหัสผ่าน" prefix={<KeyOutlined />} value={password} required onChange={(e) => onChange({ password: e.target.value })} />
      </Item>
      {error ? (
        <Tag className="text-center w-100 my-2" color="red">
          {errorStatusCode(error.status)}
        </Tag>
      ) : null}
      <Item flex>
        {/* <Button primary transparent style={{ flex: 1, marginRight: 15 }}>
          <Link to="/register">ลงทะเบียน</Link>
        </Button> */}
        <Button primary type="submit" onClick={onSubmit} style={{ flex: 1 }} disabled={email.length && password.length ? false : true}>
          {email.length && password.length ? 'เข้าสู่ระบบ' : 'โปรดกรอกอีเมล และ รหัสผ่าน'}
        </Button>
      </Item>
    </form>
  </Container>
)}

const errorStatusCode = (statusCode) => {
  switch (statusCode) {
    case 400:
      return 'ข้อมูลผิดพลาด'
    case 401:
      return 'ชื่อผู้ใช้ หรือ รหัสผ่านไม่ถูกต้อง'
    case 404:
      return 'ชื่อผู้ใช้ หรือ รหัสผ่านไม่ถูกต้อง'
    default:
      return 'เกิดข้อผิดพลาดในการเชื่อมต่อ'
  }
}

LoginForm.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  error: PropTypes.object,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
}

LoginForm.defaultProps = {
  error: {},
  onSubmit: () => {},
  onChange: () => {},
}

export default LoginForm
