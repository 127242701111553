import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Divider, DatePicker, Card, Typography, Form, Input, Radio, InputNumber, Modal, Spin, Table, Popconfirm, Select, Checkbox, message } from 'antd'
import { GoogleMap, LoadScript, Marker, Polygon, KmlLayer, useGoogleMap } from '@react-google-maps/api'
import styled from 'styled-components'
import axios from 'axios'
import moment from 'moment'
import firebase from 'firebase'
import { find } from 'lodash'
import jwtDecode from 'jwt-decode'
import { LoadingOutlined } from '@ant-design/icons'
import { AMBULANCE_STATUS } from '../../constant'
import MarkerRed from '../../images/Marker-red.png'
import MarkerGreen from '../../images/Marker-green.png'
import MarkerOrange from '../../images/Marker-orange.png'
import EmergencyTableForm from '../EmergencyTableForm'
import http from '../../services/httpService'
import httpEver from '../../services/httpServiceEver'
import ReasonLateTable from '../ReasonLateTable'
import AmbulanceTable from '../AmbulanceTable'
import EmergencyForm from '../EmergencyForm'
import DateLiveClockTime from './DateLife'
import FormableAddnewCase from './component/NewCaseForm/FormableAddnewCase'
import RenderTopTab from './component/EmsTabActions/RenderTopTab'
import './style.css'
import { Polygons, MapMarker } from './constant'
import TextBase from '../Text'

const Text = styled(TextBase)`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
`
const TextModal = styled(TextBase)`
  text-align: center;
  font-size: 36px;
  font-weight: bold;
`
const { TextArea } = Input
const { Title } = Typography
const apiEndpoint = '/dashboardData'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const Container = styled.div`
  padding: 20px;
`
const roundSmall = ''
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
  disabled: false,
}
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
}

class LoadScriptOnlyIfNeeded extends LoadScript {
  componentDidMount() {
    const cleaningUp = true
    const isBrowser = typeof document !== 'undefined' // require('@react-google-maps/api/src/utils/isbrowser')
    const isAlreadyLoaded = window.google && window.google.maps && document.querySelector('body.first-hit-completed') // AJAX page loading system is adding this class the first time the app is loaded
    if (!isAlreadyLoaded && isBrowser) {
      // @ts-ignore
      if (window.google && !cleaningUp) {
        console.error('google api is already presented')
        return
      }

      this.isCleaningUp().then(this.injectScript)
    }

    if (isAlreadyLoaded) {
      this.setState({ loaded: true })
    }
  }
}

const GoogleMapStore = ({ updateLocation, location, user, roles, updateEmsForm, visibleModalCreateStore, emsPositionAlert, ambulance, setVisibleModalCreateStore, createPharmacyStore, pharmacyStore, history }) => {
  const [role, setRole] = useState(roles[0])
  const [currentPosition, setCurrentPosition] = useState({})
  const [tab, setTab] = useState('1')
  const [ems, setEms] = useState([])
  const [emsMapMode, setEmsMapMode] = useState('both')
  const [mapref, setMapRef] = React.useState(null) // state that references Google Map component to get values out of it
  const [formStore] = Form.useForm()
  const [formAddCase] = Form.useForm()
  const [Reporter, setReporter] = useState('')
  const [visibleModalClock, setVisibleModalClock] = useState(false)
  const [visibleAddCaseModal, setVisibleAddCaseModal] = useState(false)
  const [finishedAdd, setFinishedAdd] = useState(Math.floor(Math.random() * 100))
  const [detail, setDetail] = useState(false)
  const [patient, setPatient] = useState(false)
  const [key, setKey] = useState(false)
  const googleMapsApi_Key = process.env.REACT_APP_PROJECT_ID === 'vajira-smart-hospital-dev' ? '' : 'AIzaSyBDLYwoxv5GtDACl-UE5M4aaIN77Q1MYno'

  const handleOnLoad = (map) => {
    setMapRef(map)
  }

  const validateMessages = {
    required: 'กรุณากรอก${label}',
  }

  const defaultCenter = {
    lat: 13.780631346451775,
    lng: 100.50889089539895,
  }
  const success = (position) => {
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    }
    setCurrentPosition(currentPosition)
  }
  useEffect(() => {
    if (roles.includes('pharmacyStore')) {
      if (pharmacyStore) {
        setCurrentPosition({ lat: Number(pharmacyStore.lat), lng: Number(pharmacyStore.lng) })
      } else {
        navigator.geolocation.getCurrentPosition(success)
      }
    } else if (roles.includes('staff')) {
      onChangeTab('1')
    }
  }, [])

  useEffect(() => {
    const getEms = () => {
      setEms(emsPositionAlert)
    }
    getEms()
  }, [emsPositionAlert])

  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat()
    const lng = e.latLng.lng()
    setCurrentPosition({ lat, lng })
  }
  const renderMarkerMap = () => {
    const markers = MapMarker.map((item, index) => <Marker position={{ lat: item.lat, lng: item.lng }} key={index} onDragEnd={(e) => onMarkerDragEnd(e)} icon={{ url: item.icon }} title={item.label} draggable />)
    return markers
  }

  const renderMarker = (users) => {
    if (users && users.length) {
      const userMap = users.map((user, index) => {
        const pinColor =
          ['patientWaiting', 'actionWaiting', 'exitWaiting'].includes(user.status) && tab === '1'
            ? 'red'
            : ['moving', 'arrived', 'completedSending', 'inHospital'].includes(user.status) && tab === '2'
            ? 'orange'
            : ['cancelled', 'completed'].includes(user.status) && tab === '3'
            ? 'green'
            : ''
        const pinColorCode = pinColor === 'red' ? MarkerRed : pinColor === 'green' ? MarkerGreen : pinColor === 'orange' ? MarkerOrange : ''
        return pinColorCode ? <Marker key={index} label={user.markerLabel} position={{ lat: user.lat, lng: user.lng }} onDragEnd={(e) => onMarkerDragEnd(e)} /> : null
      })
      return userMap
    } else {
      return <Marker position={{ lat: currentPosition.lat, lng: currentPosition.lng }} onDragEnd={(e) => onMarkerDragEnd(e)} icon={{ url: 'https://img.icons8.com/office/40/000000/marker.png' }} draggable />
    }
  }

  const onChangeTab = (tab) => {
    setTab(tab)
  }

  const updateAmbulance = (value, ambulanceNo) => {
    const availability = ['พร้อมใช้งาน'].includes(value) ? true : false
    if (ambulanceNo) {
      firebase
        .database()
        .ref(`ambulance/วชิระ${ambulanceNo}`)
        .update({ note: value, availability })
    }
  }

  const onCreatePharmacyStore = (values) => {
    setVisibleModalCreateStore(false)
    createPharmacyStore(values)
  }

  const onCreateAddCaseEMS = async (values) => {
    const addNewCase = {
      operationDateTime: new Date(),
      staffId: user.id,
      status: 'customAdd',
      emsType: values.emsType,
      responseData: {
        operationDateTime: new Date(),
        notifiedSymptoms: values.emergencyMessage,
        idcCode: values.idcLabel,
        vehicle: values.emsCar,
      },
      stateTimeStamp: [{ status: 'customAdd', date: new Date().toLocaleString() }],
    }
    await createNewEmsForm(addNewCase)
    updateEmsCarStatusGoOut(values.emsCar, 'รถพยาบาลไม่พร้อม')
    setVisibleAddCaseModal(false)
    formAddCase.resetFields()
  }

  const updateEmsCarStatusGoOut = (ambulanceNo, status, id) => {
    const availability = ['รถพยาบาลไม่พร้อม'].includes(status) ? false : true
    ambulanceNo = ambulanceNo.toString().split('|')[0]
    if (ambulanceNo) {
      localStorage.setItem('ambulanceNo', ambulanceNo)
    } else ambulanceNo = localStorage.getItem('ambulanceNo')
    if (ambulanceNo) {
      firebase
        .database()
        .ref(`ambulance/${ambulanceNo}`)
        .update({ note: status, availability })
    }
  }

  const createNewEmsForm = async (values) => {
    try {
      const response = await http.post('/emsForms', values)
      console.log('createForm response ==', response)
      if (response && response.status === 200) {
        setFinishedAdd(Math.floor(Math.random() * 100))
        message.success('เพิ่มเคสใหม่สำเร็จ')
      }
    } catch (error) {
      message.error('เกิดข้อผิดพลาดในการโหลดข้อมูล EMS Typologies')
    }
  }

  const ToggleClockModal = () => {
    setVisibleModalClock(!visibleModalClock)
  }

  const zoneOverlay = () => {
    // return <KmlLayer url="https://www.google.com/maps/d/kml?mid=1JGAM9ZpgAMEUZl4yWTe77bm27i3znvWD" />
    return (
      <>
        {newPolygon(Polygons.zone1, '#303030', '#303030')}
        {newPolygon(Polygons.zone2, '#9C27B0', '#9C27B0')}
        {newPolygon(Polygons.zone3, '#C2185B', '#C2185B')}
        {newPolygon(Polygons.zone4, '#E65100', '#E65100')}
        {newPolygon(Polygons.zone5, '#139D5C', '#139D5C')}
        {newPolygon(Polygons.zone6, '#A52714', '#A52714')}
        {newPolygon(Polygons.zone7, '#1A237E', '#1A237E')}
        {newPolygon(Polygons.zone8, '#0A87CB', '#0A87CB')}
        {newPolygon(Polygons.zone9, '#8dceff', '#8dceff')}
      </>
    )
  }
  const newPolygon = (p, sColor, fillColor = 'white') => {
    let opt = {
      fillColor: fillColor,
      fillOpacity: 0.2,
      strokeColor: sColor,
      strokeOpacity: 1,
      strokeWeight: 2.5,
      clickable: true,
      draggable: false,
      editable: false,
      geodesic: false,
    }
    return <Polygon path={p} options={opt}></Polygon>
  }
  const districtOverlay = () => {
    return (
      <>
        {newPolygon(Polygons.bangPhlat, '#9ECC21')}
        {newPolygon(Polygons.bangSue, '#DE6C2A')}
        {newPolygon(Polygons.dusit, '#1B9690')}
        {newPolygon(Polygons.phraNakhon, '#6217b3')}
      </>
    )
  }
  const overlayMode = (districtLayer) => {
    switch (districtLayer) {
      case 'district':
        return districtOverlay()
      case 'zone':
        return zoneOverlay()
      case 'both':
        return (
          <>
            {districtOverlay()}
            {zoneOverlay()}
          </>
        )
    }
  }

  const mapStyles = () => (roles.includes('pharmacyStore') ? { minHeight: '91vh', width: '100%' } : { minHeight: '100vh', width: '100%' })

  const createDashboard = async (userId, telemedicineCount, chatbotAiMessageCount, telemedicineTimeCount, chatbotMessageFromUserCount, emergencyCount) => {
    await httpEver.patch(apiEndpoint, {
      userId,
      telemedicineCount,
      chatbotAiMessageCount,
      telemedicineTimeCount,
      chatbotMessageFromUserCount,
      emergencyCount,
      gameCount: 0,
      diaryCount: 0,
      pickupByEmsCount: 0,
      smbgCount: 0,
      hbpmCount: 0,
      bookingStatusCount: 0,
      drugTakingCount: 0,
      drugTrackingCount: 0,
    })
  }

  const updateDashboard = async (dashboardId, data) => {
    await httpEver.patch(`${apiEndpoint}/${dashboardId}`, data)
  }

  const operations = (
    <Button className="pl-3" type="primary" onClick={() => setVisibleAddCaseModal(true)}>
      เพิ่ม
    </Button>
  )
  const reCenterMap = () => {
    if (mapref) {
      const newCenter = mapref.getCenter()
      setCurrentPosition(newCenter)
    }
  }
  const renderTopTabConstant = {
    tabInit: {
      defaultActiveKey: '1',
      onChangeTab,
      operations,
      setDetail,
      user,
      ems,
      setCurrentPosition,
      finishedAdd,
      tab,
    },
    innerTab: [
      {
        ambulance,
      },
      {},
      {
        setPatient,
        setKey,
        setReporter,
      },
      {
        ambulance,
        setFinishedAdd,
      },
    ],
  }
  return (
    <div>
      {roles.includes('pharmacyStore') ? (
        <div>
          <LoadScriptOnlyIfNeeded googleMapsApiKey={googleMapsApi_Key}>
            <GoogleMap mapContainerStyle={mapStyles()} zoom={13} center={currentPosition.lat ? currentPosition : defaultCenter}>
              {renderMarker()}
              {renderMarkerMap()}
            </GoogleMap>
          </LoadScriptOnlyIfNeeded>

          <div className="text-right">
            <Button onClick={() => updateLocation(currentPosition)}>อัปเดต Location</Button>
          </div>
        </div>
      ) : (
        <Row>
          <div className="ems-map-controls">
            <Radio.Group value={emsMapMode} onChange={(e) => setEmsMapMode(e.target.value)}>
              <Radio.Button value="district">เขต</Radio.Button>
              <Radio.Button value="zone">โซน</Radio.Button>
              <Radio.Button value="both">ทั้งหมด</Radio.Button>
            </Radio.Group>
          </div>
          <Col xs={24} sm={24} md={16} xl={16} xxl={16}>
            <LoadScriptOnlyIfNeeded googleMapsApiKey={googleMapsApi_Key}>
              <GoogleMap mapContainerStyle={mapStyles()} zoom={11} center={currentPosition.lat ? currentPosition : defaultCenter} onLoad={handleOnLoad} onCenterChanged={reCenterMap}>
                {overlayMode(emsMapMode)}
                {renderMarker(emsPositionAlert)}
                {renderMarkerMap()}
              </GoogleMap>
            </LoadScriptOnlyIfNeeded>
          </Col>
          <Col xs={24} sm={24} md={8} xl={8} xxl={8} style={{ height: '99vh', overflowY: 'auto', paddingLeft: '10px', width: '100%' }} className="d-flex flex-column">
            <div onClick={() => ToggleClockModal()}>
              <Text>
                <DateLiveClockTime />
              </Text>
            </div>
            <div style={{ minHeight: '470px', maxHeight: '470px', overflowY: 'auto' }}>
              <RenderTopTab {...renderTopTabConstant} history={history} />
            </div>
            <div>
              <AmbulanceTable ambulanceData={ambulance} className="w-100" updateAmbulance={updateAmbulance} />
            </div>
          </Col>
        </Row>
      )}
      <Modal visible={visibleModalCreateStore} title="สร้างร้านยา" width="800px" footer={null}>
        <Form form={formStore} labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} name="nest-messages" style={{ maxWidth: '100%' }} onFinish={onCreatePharmacyStore} validateMessages={validateMessages}>
          <Form.Item name="name" label="ชื่อร้าน" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="address" label="ที่อยู่" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="lat" label="Latitude" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
          <Form.Item name="lng" label="Longitude" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
          <LoadScriptOnlyIfNeeded googleMapsApiKey={googleMapsApi_Key}>
            <GoogleMap mapContainerStyle={{ height: '400px', width: '100%' }} zoom={17} center={currentPosition.lat ? currentPosition : defaultCenter}>
              {renderMarker()}
            </GoogleMap>
          </LoadScriptOnlyIfNeeded>
          <Form.Item wrapperCol={{ span: 24 }} className="text-right">
            <Button type="primary" htmlType="submit">
              บันทึก
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal className="input-round" visible={visibleAddCaseModal} onCancel={() => setVisibleAddCaseModal(false)} title="เพิ่มเคสใหม่" width="500px" footer={null}>
        <FormableAddnewCase formAddCase={formAddCase} onCreateAddCaseEMS={onCreateAddCaseEMS} validateMessages={validateMessages} ambulance={ambulance} />
      </Modal>
      <Modal visible={visibleModalClock} onOk={() => ToggleClockModal()} onCancel={() => ToggleClockModal()} title="นาฬิกา" width="600px" footer={null}>
        <TextModal>
          <DateLiveClockTime />
        </TextModal>
      </Modal>
    </div>
  )
}

export default GoogleMapStore
