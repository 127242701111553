import React from 'react'
import jwtDecode from 'jwt-decode'
import { Row, Col, Badge, Alert } from 'antd'
import { inject, observer } from 'mobx-react'
import { VideoCameraFilled, ExclamationCircleOutlined, UserOutlined, HomeOutlined, ClockCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { isEqual } from 'lodash'
import { Container, CardRoom, CardRoomHeader, CardCorner, Image, StatusTag, CardRoomText } from './styles'
import { ROLES, PRACTITIONER_ROLES, LOGISTIC } from '../../constant'
@inject('AppUserIdentity')
@inject('UserInfo')
@inject('Booking')
@observer
class RoomList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      patientDetails: [],
      loading: false,
      bookingId: '',
      role: '',
    }
  }

  convertImage = (base64) => {
    if (base64) {
      return `data:image/png;base64,${base64}`
    }
    return 'https://thumbs.dreamstime.com/b/default-avatar-photo-placeholder-profile-picture-default-avatar-photo-placeholder-profile-picture-eps-file-easy-to-edit-125707135.jpg'
  }

  checkTitleName = (patient) => {
    let name = 'ไม่พบข้อมูลลูกค้า'
    if (patient.fullname && patient.fullname.firstName) {
      const { title, firstName, lastName } = patient.fullname
      name = `${title} ${firstName} ${lastName}`
    } else if (patient.user && patient.user.firstname) {
      const { firstname, lastname } = patient.user
      name = `${firstname} ${lastname}`
    }
    return name
  }

  fetchAppUserIdentity = async (id) => {
    const { AppUserIdentity } = this.props
    const response = await AppUserIdentity.fetchAppUserIdentity(id)
    if (response && response.length) {
      return {
        omaId: response[response.length - 1].everOmaId,
        userId: response[response.length - 1].appUserId,
      }
    }
    return false
  }

  fetchPatientDetail = async () => {
    const { AppUserIdentity, UserInfo, Booking, patientStatus } = this.props
    const bookingData = await Booking.fetchBooking()
    if (bookingData && bookingData.length) {
      const bookDataFromBookId = patientStatus.filter((bookitem) => bookingData.bookingId === bookitem.id)
      if (!patientStatus.length) {
        this.setState({
          patientDetails: [],
        })
      }

      //Check => When user leave the room delete his/her room from array
      //Delete by compare between patientStatus and patientDetails if data not duplicated = delete
      if (patientStatus.length < this.state.patientDetails.length && this.state.patientDetails.length) {
        let detailsData = this.state.patientDetails
        let newData = []
        for (let ps of patientStatus) {
          newData = detailsData.map((data) => {
            if (data.bookingId === ps.bookingId) {
              return data
            }
          })
          newData = newData.filter((item) => item !== undefined)
        }
        this.setState({
          patientDetails: newData,
        })
      }
      patientStatus.forEach(async (ps) => {
        if (this.state.patientDetails && patientStatus.length && ps.bookingId) {
          console.log('ps', ps)
          const patientDetailsData = this.state.patientDetails
          const checkDuplicate = patientDetailsData.some((item) => item.bookingId === ps.bookingId)

          //Check => If this bookingId is already in the patientDetails, Just skip the Fetch info section(Use old info)
          if (!checkDuplicate) {
            const user = jwtDecode(localStorage.getItem('token'))
            const response = await this.fetchAppUserIdentity(ps.userId)

            if (response && response.omaId && response.userId) {
              const resUserInfo = await UserInfo.checkUserDetail(response.omaId)
              const resUser = await UserInfo.fetchUserInfoByPatientId(response.omaId)
              let prescriptions = await Booking.fetchBookingFilterByPrescription()

              prescriptions = this.filterPrescriptionStatus(prescriptions)

              const dataBooking = bookingData.filter((item) => ps.bookingId === item.id)
              prescriptions = prescriptions.filter((item) => ps.bookingId === item.id)
              let details = []
              const detailData = {
                doctor: dataBooking[0] && dataBooking[0].doctor ? dataBooking[0].doctor : null,
                bookingTime: dataBooking[0] && dataBooking[0].bookingTime ? dataBooking[0].bookingTime : null,
                bookingEndTime: dataBooking[0] && dataBooking[0].bookingEndTime ? dataBooking[0].bookingEndTime : null,
                admitTime: dataBooking[0] && dataBooking[0].admitTime ? dataBooking[0].admitTime : null,
                metaclinic: bookDataFromBookId[0].metaclinic,
                ...ps,
                ...resUserInfo,
                user: resUser,
                id: response.userId,
                patientId: response.omaId,
                vnNumber: dataBooking[0] && dataBooking[0].vnNumber ? dataBooking[0].vnNumber : null,
                prescriptions,
                prescription: dataBooking[0] && dataBooking[0].prescription ? dataBooking[0].prescription : null,
                props: this.props,
                bookingType: (dataBooking[0] && dataBooking[0].bookingType) || null,
                booking: dataBooking[0],
              }
              console.log('detailData', detailData)
              details.push(detailData)
              this.setState({
                patientDetails: [...this.state.patientDetails, ...details],
                role: user.roles[0],
              })
            }
          }
        }
      })
    }
  }

  filterPrescriptionStatus = (prescriptions) => {
    const user = jwtDecode(localStorage.getItem('token'))
    if (prescriptions) {
      return prescriptions.filter((res) => {
        if (user.roles.includes('pharmacy') && res.prescription && res.prescription.status) {
          return [
            'PHARMACY_PENDING_RX',
            'PHARMACY_CONFIRM_RX',
            'PATIENT_PENDING_PAYMENT',
            'PATIENT_SUCCESS_PAYMENT',
            'PATIENT_DECLINE_PAYMENT',
            'WAIT_FOR_PATIENT_EMS',
            'WAIT_FOR_PATIENT_PHARMACY',
            'WAIT_FOR_PATIENT_PHAMACYSTORE',
            'SUCCESS_BY_PHARMACY',
            'WAIT_FOR_PHARMACYSTORE_NOTIFY',
          ].includes(res.prescription.status)
        }
        if (user.roles.includes('pharmacyStore') && res.prescription && res.prescription.status) {
          return ['WAIT_FOR_PHARMACYSTORE_NOTIFY', 'WAIT_FOR_PATIENT_PHAMACYSTORE', 'SUCCESS_BY_PHARMACYSTORE', 'CANCELED_BY_PATIENT', 'CANCELED_BY_PHARMACYSTORE'].includes(res.prescription.status)
        }
      })
    }
  }

  fetchUserVisitedByVerifiedFromHIE = (patientId, vnNumber) => {
    const { UserInfo } = this.props
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const everOmaId = await this.fetchAppUserIdentity(patientId)
        await UserInfo.triggerUpdateData(everOmaId)
        const response = await UserInfo.checkDrugsDetailByOrderNo(everOmaId, vnNumber)
        this.setState({
          billingItems: vnNumber ? response.billingItems : [],
          isLoading: false,
        })
      },
    )
  }

  componentDidMount() {
    this.mount()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.patientStatus.length !== this.props.patientStatus.length) {
      this.fetchPatientDetail()
    }
  }

  mount = () => {
    const { patientStatus } = this.props
    if (this.state.patientDetails.length !== patientStatus.length && patientStatus.length) {
      this.fetchPatientDetail()
    }
  }

  checkPatientStatus = (patientDetail) => {
    const { roles, id } = jwtDecode(localStorage.getItem('token'))
    if (PRACTITIONER_ROLES.includes(roles[0])) {
      return { path: `patient${patientDetail.id}${roles[0]}${id}`, date: patientDetail.date }
    } else if (roles[0] === 'callCenter') {
      return { path: `${patientDetail.key}`, date: patientDetail.date }
    }
    return { path: `patient${patientDetail.id}pharmacy`, date: patientDetail.date }
  }

  showPatientLogistic = (logisticId) => {
    if (logisticId) {
      return LOGISTIC[logisticId]
    }
    return 'ไม่พบวิธีจัดส่งยา'
  }

  render() {
    const { patientDetails, loading, bookingId, role } = this.state
    const { onSetRoomList, onSetPatient, onSetRoomId, onSetBookingId, fetchImage, setDate, isMobileDevice, onSetBooking } = this.props
    return !loading && patientDetails.length ? (
      <Container>
        <Row>
          {patientDetails.length &&
            patientDetails.map((patientDetail, index) => (
              <Col span={24} key={patientDetail.patientId + index}>
                <CardRoom
                  status={patientDetail.status}
                  onClick={() => {
                    const { roles } = jwtDecode(localStorage.getItem('token'))
                    onSetPatient(patientDetail)
                    onSetRoomId(this.checkPatientStatus(patientDetail))
                    onSetRoomList(false)
                    onSetBooking(patientDetail.booking)
                    onSetBookingId(patientDetail.bookingId)
                    fetchImage(patientDetail.patientId)
                    setDate(patientDetail.date)
                  }}
                >
                  <Row type="flex" gutter={30} className="on_row">
                    <Col span={6} className="d-flex align-items-center justify-content-center relative_card">
                      <div>
                        <Image src={this.convertImage(patientDetail.imageBase64)} className="response_img" />
                      </div>
                    </Col>
                    <Col span={17} className="name_center">
                      <CardRoomHeader className="d-flex align-items-center">
                        {this.checkTitleName(patientDetail)}{' '}
                        <StatusTag className="mx-3 room_info" status={patientDetail.status} type={['calling', 'doctorJoin'].includes(patientDetail.status) ? 'error' : 'success'}>
                          {patientDetail.status === 'calling' ? 'ผู้ป่วยกำลังพบแพทย์' : patientDetail.status === 'inWaitingRoom' ? 'ผู้ป่วยอยู่ในห้องรอ' : patientDetail.status === 'doctorJoin' && 'เจ้าหน้าที่อยู่ในห้องรอ'}
                        </StatusTag>
                      </CardRoomHeader>
                      <CardRoomText className="d-flex align-items-center room_info">
                        {['doctor', 'pharmacy'].includes(patientDetail.doctor.roles[0].name) ? 'แพทย์ผู้สั่งยา' : ROLES[patientDetail.doctor.roles[0].name]} : {(patientDetail.doctor && patientDetail.doctor.fullname) || '-'}
                      </CardRoomText>
                      <CardRoomText className="d-flex align-items-center room_info">คลินิก {patientDetail.doctor.detail ? patientDetail.doctor.detail.department : 'ไม่ระบุ'}</CardRoomText>
                      <CardRoomText className="d-flex align-items-center room_info">
                        เวลานัดหมาย {moment(patientDetail.admitTime).format('DD/MM/YYYY')}
                        {' - '}
                        {patientDetail.bookingEndTime
                          ? ` ${moment()
                              .startOf('week')
                              .add(patientDetail.bookingTime, 'minutes')
                              .format('HH:mm')} - ${moment()
                              .startOf('week')
                              .add(patientDetail.bookingEndTime, 'minutes')
                              .format('HH:mm')}`
                          : `  ${moment()
                              .startOf('week')
                              .add(patientDetail.bookingTime, 'minutes')
                              .format('HH:mm')}`}{' '}
                        น.
                      </CardRoomText>
                      {patientDetail.doctor.roles[0].name === 'doctor' && (
                        <CardRoomText className="d-flex align-items-center room_info">{this.showPatientLogistic(patientDetail.prescription ? patientDetail.prescription.logisticId : null)}</CardRoomText>
                      )}
                      <CardRoomText color="#969696" className="room_info">
                        {patientDetail.date
                          ? moment(moment().valueOf() - patientDetail.date).format('mm') < 3
                            ? `เข้ามาในห้องรอเมื่อ ${moment(patientDetail.date).format('HH:mm')} (อยู่ในห้องรอ ไม่กี่นาทีที่แล้ว)`
                            : `เข้ามาในห้องรอเมื่อ ${moment(patientDetail.date).format('HH:mm')}
                               (อยู่ในห้องรอ ${moment.duration(moment().valueOf() - moment(patientDetail.date)).hours() > 0 ? `${moment.duration(moment().valueOf() - moment(patientDetail.date)).hours()} ชั่วโมง` : ''}
                               ${moment.duration(moment().valueOf() - moment(patientDetail.date)).minutes()} นาทีแล้ว)`
                          : 'ไม่ระบุเวลาเขัา'}
                      </CardRoomText>
                      {isMobileDevice && patientDetail.user.deviceInfo && patientDetail.user.deviceInfo.manufacturer.includes('vivo') && (
                        <Alert message="กรุณาเข้าผ่านแอปพลิเคชัน หรือเว็บบราวเซอร์บนคอมพิวเตอร์เท่านั้น" type="error" showIcon icon={<ExclamationCircleOutlined />} />
                      )}
                    </Col>
                  </Row>
                  <CardCorner status={patientDetail.status}>
                    <VideoCameraFilled />
                  </CardCorner>
                </CardRoom>
              </Col>
            ))}
        </Row>
      </Container>
    ) : (
      ''
    )
  }
}
export default RoomList
