import firebase from 'firebase/app'
import 'firebase/database'
import constant from '../constant'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

function signInFirebase() {
  const firebaseAccount = process.env.REACT_APP_FIREBASE_AUTH_ACCOUNT
  const firebasePassword = process.env.REACT_APP_FIREBASE_AUTH_PASSWORD
  firebase
    .auth()
    .signInWithEmailAndPassword(firebaseAccount, firebasePassword)
    .catch((error) => {
      console.warn(`${error.code} ${error.message}`)
    })
}

export const initializeFirebase = () => {
  firebase.initializeApp(config)
  // signInFirebase()
  // return firebase.messaging()
}

export const askForPermissionToReceiveNotifications = async () => {
  try {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging()
      await messaging.requestPermission()
      const token = await messaging.getToken()
      localStorage.setItem(constant.PUSH_TOKEN, token)
      return null
    }
  } catch (error) {
    throw error
  }
}

export const getMessaging = () => {
  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging()
    return messaging
  }
}
