import React, { useState, useEffect } from 'react'
import Video from 'twilio-video'
import { Button, Row, Col, Popconfirm, Tabs, Layout } from 'antd'
import { DownloadOutlined, PhoneOutlined, AudioOutlined, VideoCameraOutlined, AudioMutedOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import useWindowDimensions from '../../hooks/Video/useWindowDimensions/useWindowDimensions'
import UploadImage from '../../components/UploadImage'
import Loading from '../../components/Loading'
import Participant from './Participant'
import Note from './Note'
import Chat from './Chat'
import Timer from './Timer'
import { SideBar, CircleButtonNormal, CircleButtonAlert, TabSidebar, RightSideBar } from './styles'
import RoomDetail from './RoomDetail'
import httpEver from '../../services/httpServiceEver'
import InternetMeter from './InternetMeter'
import Telemonitoring from '../../components/Modal/Telemonitoring'
import { AreaChartOutlined } from '@ant-design/icons'
import jwtDecode from 'jwt-decode'

const apiEndpoint = '/dashboardData'

const { TabPane } = Tabs

const Room = ({ roomName, token, handleLogout, patient, setToComplete, bookingId, createBookingRoom, updateBookingNote, summaryNote, setPatient, practitionerRole, onSetRoomList, onSetRoomDetail, isTestVideoCall, booking }) => {
  const [room, setRoom] = useState(null)
  const [sid, setSid] = useState(false)
  const [participants, setParticipants] = useState([])
  const [visibleModalUploadImage, setVisibleModalUploadImage] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [collapsed, setCollapsed] = useState(true)
  const [rightCollapsed, setRightCollapsed] = useState(true)
  const [videoWidth, setVideoWidth] = useState(0)
  const [isMute, setIsMute] = useState(false)
  const [isDisableVideo, setIsDisableVideo] = useState(false)
  const [note, setNote] = useState(false)
  const ratio = 1.2
  const { width, height } = useWindowDimensions()
  const [image, setImage] = useState(false)
  const [rotate, setRotate] = useState(false)
  const [visibleTelemonitoring, setVisibleTelemonitoring] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState(jwtDecode(localStorage.getItem('token')))

  const closeRoomBeforeHostLeave = () => {
    summaryNote(note)
    createBookingRoom(patient.bookingId, sid)
    updateBookingNote(patient.bookingId, note)
    setToComplete(roomName)
    handleLogout()
    disconnect()
  }
  useEffect(() => {
    return () => {
      window.removeEventListener('beforeunload', () => {
        closeRoomBeforeHostLeave()
      })
    }
  }, [])

  window.onpopstate = (e) => {
    closeRoomBeforeHostLeave()
  }
  window.addEventListener('beforeunload', () => {
    closeRoomBeforeHostLeave()
  })

  useEffect(() => {
    if (participants.length) {
      const num = collapsed && rightCollapsed ? 0 : 1
      const count = participants.length + num
      let video = width / (ratio * count)
      if (video > 900) {
        video = 900
      }
      setVideoWidth(video)
      fetchImage(patient.patientId)
    }
  }, [participants, collapsed, width, rightCollapsed])

  const disconnect = () => {
    if (room && room.localParticipant.state === 'connected') {
      room.localParticipant.tracks.forEach((trackPublication) => {
        trackPublication.track.stop()
      })
      room.disconnect()
      return null
    }
  }

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant])
    }
    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant))
    }
    try {
      Video.connect(token, {
        name: roomName,
        RecordParticipantsOnConnect: true,
        networkQuality: { local: 1, remote: 1 },
        video: true,
        audio: true,
      }).then(
        (room) => {
          setRoom(room)
          setSid(room.sid)
          room.on('participantConnected', participantConnected)
          room.on('participantDisconnected', participantDisconnected)
          room.participants.forEach(participantConnected)
          setParticipants((prev) => [room.localParticipant, ...prev])
        },
        (error) => {
          console.log(`Unable to connect to Room`)
          console.log(error)
          if (!alert('โปรดตรวจสอบการอนุญาติเข้าถึง internet ภายนอก')) window.open('http://www.google.com')
        },
      )
    } catch (error) {
      console.log('error videocall')
      console.log(error)
    }

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach((trackPublication) => {
            trackPublication.track.stop()
          })
          currentRoom.disconnect()
          return null
        }
        return currentRoom
      })
    }
  }, [roomName, token])

  const toggleAudio = (isMute) => {
    if (isMute) {
      room.localParticipant.audioTracks.forEach((track) => {
        track.track.disable()
      })
    } else {
      room.localParticipant.audioTracks.forEach((track) => {
        track.track.enable()
      })
    }
  }

  const checkAudioButton = (isMute) => {
    if (isMute) {
      return (
        <CircleButtonAlert
          onClick={() => {
            toggleAudio(false)
            setIsMute(false)
          }}
        >
          <AudioMutedOutlined />
        </CircleButtonAlert>
      )
    }
    return (
      <CircleButtonNormal
        onClick={() => {
          toggleAudio(true)
          setIsMute(true)
        }}
      >
        <AudioOutlined />
      </CircleButtonNormal>
    )
  }

  const checkVideoButton = (isDisableVideo) => {
    if (isDisableVideo) {
      return (
        <CircleButtonAlert
          onClick={() => {
            toggleVideo(false)
            setIsDisableVideo(false)
          }}
        >
          <VideoCameraOutlined />
        </CircleButtonAlert>
      )
    }
    return (
      <CircleButtonNormal
        onClick={() => {
          toggleVideo(true)
          setIsDisableVideo(true)
        }}
      >
        <VideoCameraOutlined />
      </CircleButtonNormal>
    )
  }

  const toggleVideo = (isMute) => {
    if (isMute) {
      room.localParticipant.videoTracks.forEach((track) => {
        track.track.disable()
      })
    } else {
      room.localParticipant.videoTracks.forEach((track) => {
        track.track.enable()
      })
    }
  }

  const fetchImage = async (patientId) => {
    const response = await httpEver.get(`/kycPatientImages/filterByPatientId?patientId=${patientId}`)
    if (response.status === 200) {
      setImage(response.data[0].image)
    } else {
      setImage(false)
    }
  }

  const patchLastCallAt = async (id, lastCallAt) => {
    const { data } = await httpEver.patch(`/Bookings`, {
      id,
      lastCallAt,
    })
    console.log('data= ==== ==== ===', data)
  }

  const CustomTrigger = () => {
    return (
      <div
        style={{
          transform: rotate ? 'rotateY(180deg)' : 'rotateY(0deg)',
        }}
      >
        <svg viewBox="64 64 896 896" focusable="false" data-icon="left" width="1em" height="1em" fill="currentColor" aria-hidden="true">
          <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
        </svg>
      </div>
    )
  }
  const updateVideocallCount = async (userId) => {
    const response = await httpEver.get(`${apiEndpoint}/?filter[where][userId]=${userId}`)
    if (response.data.length != 0) {
      const dashboardId = response.data[0].id
      const telemedicineCount = response.data[0].telemedicineCount + 1
      const data = {
        telemedicineCount,
      }
      await updateDashboard(dashboardId, data)
    } else {
      await createDashboard(userId, 1, 0, 0, 0, 0)
    }
  }

  const createDashboard = async (userId, telemedicineCount, chatbotAiMessageCount, telemedicineTimeCount, chatbotMessageFromUserCount, emergencyCount) => {
    await httpEver.patch(apiEndpoint, {
      userId,
      telemedicineCount,
      chatbotAiMessageCount,
      telemedicineTimeCount,
      chatbotMessageFromUserCount,
      emergencyCount,
      gameCount: 0,
      diaryCount: 0,
      pickupByEmsCount: 0,
      smbgCount: 0,
      hbpmCount: 0,
      bookingStatusCount: 0,
      drugTakingCount: 0,
      drugTrackingCount: 0,
    })
  }

  const updateDashboard = async (dashboardId, data) => {
    await httpEver.patch(`${apiEndpoint}/${dashboardId}`, data)
  }

  return room ? (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#263240' }}>
      <>
        <UploadImage visible={visibleModalUploadImage} setVisible={(condition) => setVisibleModalUploadImage(condition)} bookingId={patient.bookingId} />
        <SideBar
          theme="light"
          collapsible
          collapsedWidth="0"
          width="600"
          collapsed={collapsed}
          onCollapse={(collapsed) => {
            setCollapsed(collapsed)
            setRightCollapsed(true)
          }}
          style={{ maxHeight: '100vh' }}
        >
          <TabSidebar defaultActiveKey="1" tabPosition="bottom">
            <TabPane tab="Note" key="1" style={{ width: '100%', justifyContent: 'center', height: '92vh', overflow: 'auto' }}>
              <Note emitNote={(note) => setNote(note)} bookingNotes={booking.notes} />
            </TabPane>
            <TabPane tab="Chat" key="2" style={{ width: '100%', justifyContent: 'center' }}>
              <Chat patient={patient} booking={booking} isRoom />
            </TabPane>
          </TabSidebar>
        </SideBar>
      </>
      <Layout className="site-layout" collapsedWidth={0} style={{ backgroundColor: '#263240' }}>
        <Timer />
        <InternetMeter />
        <Row gutter={24} justify="center">
          {participants.map((participant, index) => (
            <Participant patient={patient} index={index} className="patient-call" key={participant.sid} participant={participant} videoWidth={videoWidth} />
          ))}
          <Col span={24}>
            <Row gutter={24} justify="center">
              <Col>{checkAudioButton(isMute)}</Col>
              <Col>{checkVideoButton(isDisableVideo)}</Col>
              <Col>
                <Popconfirm
                  title="คุณต้องการจะวางสายใช่หรือไม่"
                  onConfirm={() => {
                    if (practitionerRole === 'callCenter') {
                      onSetRoomDetail(false)
                      onSetRoomList(true)
                    }
                    patchLastCallAt(patient.bookingId, new Date())
                    summaryNote(note)
                    createBookingRoom(patient.bookingId, sid)
                    updateBookingNote(patient.bookingId, note)
                    setToComplete(roomName)
                    updateVideocallCount(patient.userId)
                    handleLogout()
                    disconnect()
                  }}
                  okText="ใช่"
                  cancelText="ไม่"
                >
                  <CircleButtonAlert>
                    <PhoneOutlined />
                  </CircleButtonAlert>
                </Popconfirm>
              </Col>
              <Telemonitoring visible={visibleTelemonitoring} setVisible={(visibleStatus) => setVisibleTelemonitoring(visibleStatus)} patientInfo={patient} isLoading={isLoading} setIsLoading={setIsLoading} />
              {!isTestVideoCall && (
                <Col style={{ alignSelf: 'center' }}>
                  <Button type="primary" onClick={() => setVisibleModalUploadImage(true)}>
                    อัปโหลดรูปภาพ
                  </Button>
                </Col>
              )}
              {user.roles[0] === 'doctor' && (
                <Col style={{ alignSelf: 'center' }}>
                  <Button type="primary" icon={<AreaChartOutlined />} onClick={() => setVisibleTelemonitoring(true)}>
                    Telemonitoring
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Layout>
      <RightSideBar
        theme="light"
        trigger={<CustomTrigger />}
        collapsible
        collapsedWidth="0"
        width="700"
        collapsed={rightCollapsed}
        onCollapse={(rightCollapsed) => {
          setRightCollapsed(rightCollapsed)
          setCollapsed(true)
          setRotate(!rotate)
        }}
        style={{ maxHeight: '100vh' }}
      >
        <RoomDetail patient={patient} image={image} />
      </RightSideBar>
    </Layout>
  ) : (
    <Loading />
  )
}

export default Room
