import React, { useState, useEffect } from 'react'
import { Modal, Button, Input, Typography } from 'antd'
import styled from 'styled-components'
import { PlusOutlined } from '@ant-design/icons'
import moment from 'moment'

const { TextArea } = Input

const Container = styled.div`
  padding: 20px;
`
const { Title } = Typography

function Note(props) {
  const [visibleModal, setVisibleModal] = useState(false)
  const [notes, setNotes] = useState([])
  const [topic, setTopic] = useState('')
  const { clearNotes, setClearNotes, bookingNotes } = props
  useEffect(() => {
    if (notes) {
      props.emitNote(notes)
    }
  }, [notes])

  useEffect(() => {
    if (clearNotes === true) {
      setNotes([])
      setClearNotes(false)
    }
  }, [clearNotes])
  useEffect(() => {
    if (bookingNotes) {
      setNotes(bookingNotes)
    }
  }, [bookingNotes])

  const createNote = () => {
    setNotes((prevState) => [...prevState, { id: notes.length + 1, topic, description: '' }])
  }

  const onChangeDescription = (value, index) => {
    setNotes((prevNotes) =>
      prevNotes.map((note, idx) => {
        if (index === idx) {
          return { ...prevNotes[index], description: value, timestamp: moment().format() }
        }
        return note
      }),
    )
  }
  return (
    <Container>
      <Button type="primary" block icon={<PlusOutlined />} size="large" onClick={() => setVisibleModal(true)}>
        หัวข้อ
      </Button>
      <Modal
        visible={visibleModal}
        onOk={() => {
          createNote()
          setVisibleModal(false)
          setTopic('')
        }}
        onCancel={() => {
          setVisibleModal(false)
          setTopic('')
        }}
        title="เพิ่มหัวข้อ"
      >
        <Input placeholder="กรอกหัวข้อ" value={topic} onChange={(e) => setTopic(e.target.value)} />
      </Modal>
      {notes.length
        ? notes.map((note, index) => (
            <div style={{ marginTop: 20 }}>
              <Title level={3}>{`${note.timestamp ? moment(note.timestamp).format('DD/MM/YYYY') + ' - ' : ''}${note.topic}`}</Title>
              <TextArea rows={4} value={note.description} onChange={(e) => onChangeDescription(e.target.value, index)} />
            </div>
          ))
        : ''}
    </Container>
  )
}
export default Note
