import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Image, Button } from 'antd'
import moment from 'moment'
import ModalMedicineEphis from '../../components/Modal/MedicineEphis'
import jwtDecode from 'jwt-decode'

const RoomDetail = ({ patient, image }) => {
  const [ems, setEms] = useState(false)
  const [visibleModalMedicine, setVisibleModalMedicine] = useState(false)
  const [prescription, setPrescription] = useState(false)
  const [visibleModalEms, setVisibleModalEms] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [emsAvailable, setEmsAvailable] = useState(false)
  const [billingItems, setBillingItems] = useState([])
  const [drugsDetail, setDrugsDetail] = useState({})
  const [roles, setRoles] = useState('')

  useEffect(() => {
    mount()
  }, [])

  const fetchAppUserIdentity = async (id) => {
    const { AppUserIdentity } = patient.props
    const response = await AppUserIdentity.fetchAppUserIdentity(id)
    if (response && response.length) {
      return response[response.length - 1].everOmaId
    } else {
      return false
    }
  }

  const fetchUserVisitedByVerifiedFromHIE = async (patientId, vnNumber) => {
    const { UserInfo } = patient.props
    setIsLoading(true)
    const everOmaId = await fetchAppUserIdentity(patientId)
    await UserInfo.triggerUpdateData(everOmaId)
    const response = await UserInfo.checkDrugsDetailByOrderNo(everOmaId, vnNumber)
    setDrugsDetail(response)
    setBillingItems(vnNumber ? response.billingItems : [])
    setIsLoading(false)
  }

  const mount = async () => {
    const { Booking, Logistic } = patient.props
    const user = jwtDecode(localStorage.getItem('token'))
    let response
    response = await Booking.fetchBookingFilterByPrescription()
    response = response.filter((res) => {
      if (user.roles.includes('pharmacy') && res.prescription && res.prescription.status) {
        return [
          'PHARMACY_PENDING_RX',
          'PHARMACY_CONFIRM_RX',
          'PATIENT_PENDING_PAYMENT',
          'PATIENT_SUCCESS_PAYMENT',
          'PATIENT_DECLINE_PAYMENT',
          'WAIT_FOR_PATIENT_EMS',
          'WAIT_FOR_PATIENT_PHARMACY',
          'WAIT_FOR_PATIENT_PHAMACYSTORE',
          'SUCCESS_BY_PHARMACY',
          'WAIT_FOR_PHARMACYSTORE_NOTIFY',
        ].includes(res.prescription.status)
      } else if (user.roles.includes('pharmacyStore') && res.prescription && res.prescription.status) {
        return ['WAIT_FOR_PHARMACYSTORE_NOTIFY', 'WAIT_FOR_PATIENT_PHAMACYSTORE', 'SUCCESS_BY_PHARMACYSTORE', 'CANCELED_BY_PATIENT', 'CANCELED_BY_PHARMACYSTORE'].includes(res.prescription.status)
      }
    })
    setRoles(user.roles)
    setPrescription(response)
  }

  const convertHn = (patient) => {
    const hn = patient.hn && patient.hn.length && patient.hn[0].hn
    if (!hn) return
    const hnString = hn.toString()
    const second = hnString.substring(0, 2)
    const past = hnString.substring(2).split('')
    let pass = 0
    const final = past.map((string) => {
      if (pass || string !== '0') {
        pass = 1
        return string
      }
    })
    return final.join('').concat('/', second)
  }

  const convertImage = (base64) => {
    if (base64) {
      return `data:image/png;base64,${base64}`
    }
    return 'https://thumbs.dreamstime.com/b/default-avatar-photo-placeholder-profile-picture-default-avatar-photo-placeholder-profile-picture-eps-file-easy-to-edit-125707135.jpg'
  }

  function calcAge(dateString, convert = false) {
    let birthday = +new Date(dateString)
    let age = ~~((Date.now() - birthday) / 31557600000)
    if (convert) {
      age += 543
    }
    return age
  }
  return (
    <div style={{ backgroundColor: '#fafafa', height: '100%', padding: 20 }}>
      <h3>ประวัติผู้ป่วย</h3>
      <Row
        style={{
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ModalMedicineEphis
          addTrackingNumber={(quantity) => addTrackingNumber(prescriptionId, quantity)}
          isLoading={isLoading}
          emsAvailable={emsAvailable}
          visible={visibleModalMedicine}
          ems={ems}
          setEms={(ems) => setEms(ems)}
          setVisible={(visible) => setVisibleModalMedicine(visible)}
          isSuccess={(status) => {
            setStatusPrescription(prescriptionId, 'PHARMACY_CONFIRM_RX')
            fetchUserFcmToken(patientId, 'เภสัชกรยืนยันรายการยาเรียบร้อย')
          }}
          prescription={prescription}
          fetchUserVisitedByVerifiedFromHIE={() => fetchUserVisitedByVerifiedFromHIE(patient.Id, patient.vnNumber)}
          drugsDetail={drugsDetail}
          items={billingItems}
          style={{ overflow: 'scroll !important' }}
        />
        <div className="text-center col-6">
          {/* <img
            src={convertImage(patient.imageBase64)}
            className="rounded-circle mt-4"
            style={{ width: '30%', boxShadow: '0px 0px 5px #e4e4e4' }}
          />
          <h3 className="mt-4">
            {patient.fullname
              ? `${patient.fullname.title} ${patient.fullname.firstName} ${patient.fullname.lastName}`
              : ''}
          </h3>
          <h4 className="mt-1" style={{ color: '#cfcfcf' }}>
            {patient.cid}
          </h4> */}
          {!patient.contactInfo && !patient.emergencyInfo ? (
            <div>ไม่พบข้อมูลจาก HIE</div>
          ) : (
            <Row className="mt-4">
              <Col className="text-center p-2" span={24}>
                <Image className="rounded" src={convertImage(patient.imageBase64)} width={170} />
              </Col>
              <Col className="text-center pb-2" style={{ fontWeight: 'bold' }} span={24}>
                รูปภาพจาก E-PHIS
              </Col>
              <Col span={8} className="text-left">
                เพศ:
              </Col>
              <Col span={16} className="text-left">
                {patient.sexName}
              </Col>
              <Col span={8} className="text-left">
                HN:
              </Col>
              <Col span={16} className="text-left">
                {convertHn(patient)}
              </Col>
              <Col span={8} className="text-left">
                อายุ:
              </Col>
              <Col span={16} className="text-left">
                {patient.DOB ? `${calcAge(patient.DOB)} ปี` : 'ไม่พบข้อมูลอายุผู้ป่วย'}
              </Col>
              <Col span={8} className="text-left">
                เบอร์ติดต่อ:
              </Col>
              <Col span={16} className="text-left">
                {patient.contactInfo ? patient.contactInfo.mobileNumber : '-'}
              </Col>
              <Col span={8} className="text-left">
                เบอร์ติดต่อฉุกเฉิน:
              </Col>
              <Col span={16} className="text-left">
                {patient.emergencyInfo ? `${patient.emergencyInfo.mobileNumber} ${patient.emergencyInfo.relationship ? `(${patient.emergencyInfo.relationship})` : '-'}` : '-'}
              </Col>
              <Col span={24} className="text-left">
                แพ้ยา: {patient.drugAllergies.length ? '' : '-'}
              </Col>
              <Col span={24} className="text-left">
                {patient.drugAllergies.length &&
                  patient.drugAllergies.map((drugAllergy, index) => (
                    <Row>
                      <Col span={1}>{index + 1}.</Col>
                      <Col offset={1} span={6}>
                        ชื่อยา
                      </Col>
                      <Col span={16}>{drugAllergy.genericname}</Col>
                      <Col offset={2} span={6}>
                        อาการ
                      </Col>
                      <Col span={16}>{drugAllergy.symptom}</Col>
                    </Row>
                  ))}
              </Col>
            </Row>
          )}
        </div>
        <div className="text-center col-6">
          {patient ? (
            <Row>
              {image ? (
                <Col className="text-center p-2" span={24}>
                  <Image className="rounded" src={image} width={170} height={200} />
                </Col>
              ) : (
                <Col span={24} style={{ textAlign: 'center', margin: 15 }}>
                  <span>ไม่มีรูปภาพ</span>
                </Col>
              )}
              <Col className="text-center pb-2" style={{ fontWeight: 'bold' }} span={24}>
                รูปภาพจาก App
              </Col>
              <Col className="text-left" span={8}>
                ชื่อ
              </Col>
              <Col className="text-left" span={16}>
                {patient.fullname ? `${patient.fullname.title} ${patient.fullname.firstName} ${patient.fullname.lastName}` : `${patient.user.firstname} ${patient.user.lastname}`}
              </Col>
              <Col className="text-left" span={8}>
                อายุ
              </Col>
              <Col className="text-left" span={16}>
                {patient.user?.birthDate ? `${calcAge(patient.user?.birthDate, true)} ปี` : 'ไม่พบข้อมูลอายุผู้ป่วย'}
              </Col>
              <Col className="text-left" span={8}>
                HN
              </Col>
              <Col className="text-left" span={16}>
                {(patient && convertHn(patient)) || '-'}
              </Col>
              <Col className="text-left" span={8}>
                เบอร์ติดต่อ
              </Col>
              <Col className="text-left" span={16}>
                {patient.user?.mobileNumber || '-'}
              </Col>
              <Col className="text-left" span={8}>
                เลขบัตรประชาชน
              </Col>
              <Col className="text-left" span={16}>
                {patient.cId ? patient.cId : (patient.user?.cId || '')}
              </Col>
              <Col className="text-left" span={8}>
                ที่อยู่ปัจจุบัน
              </Col>
              <Col className="text-left" span={16}>
                {`${patient.user?.address?.address || ''} ตำบล ${patient.user?.address?.address2 || ''}, อำเภอ ${patient.user?.address?.area || ''}, จังหวัด ${patient.user?.address?.province ||''} ${patient.user?.address?.postalCode || ''}`}
              </Col>
              <Col className="text-left" span={8}>
                สัญชาติ
              </Col>
              <Col className="text-left" span={16}>
                {patient?.nationality || '-'}
              </Col>
            </Row>
          ) : (
            <Row>
              {image ? (
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Image src={image} width={200} height={200} />
                </Col>
              ) : (
                <Col span={24} style={{ textAlign: 'center', margin: 15 }}>
                  <span>ไม่มีรูปภาพ</span>
                </Col>
              )}
              <Col className="text-center" style={{ paddingTop: 23 }} span={24}>
                ไม่พบข้อมูล
              </Col>
            </Row>
          )}
        </div>
        <div className="text-center mb-auto">
          <style
            dangerouslySetInnerHTML={{
              __html: `
              .site-button-ghost-wrapper .btnSet-hover:hover {
                background-color: #fafafa !important;
              }
              .ant-layout-sider-children > div{
                overflow : scroll !important;
              }
            `,
            }}
          />
          <div className="site-button-ghost-wrapper col-12">
            {patient.vnNumber ? (
              <Button
                style={{ backgroundColor: '#45c1b8' }}
                class="btnSet-hover"
                primary={1}
                onClick={() => {
                  setVisibleModalMedicine(true)
                  setPrescription(prescription)
                  fetchUserVisitedByVerifiedFromHIE(patient.id, patient.vnNumber)
                }}
              >
                รายการยา E-PHIS
              </Button>
            ) : (
              roles[0] === 'pharmacy' && (
                <Button disabled style={{ backgroundColor: '#8c8c8c', color: '#fafafa' }} primary={1}>
                  ไม่มีรายการยา E-PHIS
                </Button>
              )
            )}
          </div>
        </div>
      </Row>
    </div>
  )
}
export default RoomDetail
